 
 import React, { Component, useEffect, useRef, useState }  from 'react';
 
 import './MessageItem.scss';   
import MessageContent from './MessageContent';
import Helpers from '../helpers';

 
function MessageItem({message}) {
  

  let isSameUser = window.userData && window.userData.userId == message.senderId;
  let me = window.userData;
  
  // console.log('issame', isSameUser, message.content);

  const geetMessageDate = (insertedDate) =>{
    if(insertedDate.getTime()>(new Date().getTime()-24*60*60*1000) && insertedDate.getDate()==new Date().getDate()){
      return Helpers.dateToString( message.insertedDate, 'hh:mm');
    }
    else{
      return Helpers.dateToString( message.insertedDate, 'YYYY-MM-DD hh:mm');
    }
  }

  return ( <div className={'message-item ' + (isSameUser?' me ':'')} key={message.id} >
              <div className='avatar'><img src={message.avatarUrl || '/guest.png'}  /></div>
              <div className='name' >@{isSameUser?me.userName : message.userName}</div>
              <div className='time'  >{geetMessageDate( message.insertedDate)}</div>
              <MessageContent message={message} />
              
              
            </div>  
  );
}

export default MessageItem;
