import * as signalR from "@microsoft/signalr";
import { ChatStore } from "./Messages/ChatStore";
 

const initSocket = () => {

    if(window.messages && window.messages.inited){
        return;
    }

    if(!window.messages){
        window.messages = {};
    }

    window.messages.inited = true;
    

    let connection = new signalR.HubConnectionBuilder()
        .withUrl(window.VARS.apiUrl + "ChatHub", {
            accessTokenFactory: () => (window.token || localStorage['token']),
            skipNegotiation: true,
            transport: signalR.HttpTransportType.WebSockets
        })
        .build();

    connection.on("notification", data => {
        console.log(data);
        window.sns.notify({ title: 'a', text: 'a', time: 400 });
    });

    connection.start();
    // .then(() => connection.invoke("SendMessage", "1", "Hello"));

    window.messages.send = ({receiverId, content}) => connection.invoke("SendMessage", receiverId.toString(), content);

    console.log('init socket',window.messages.send )


    connection.on("ReceiveMessage", (sender, message) => {
        // const li = document.createElement("li");
        // li.textContent = `${sender.userName}: ${message}`;
        // document.getElementById("messageList").appendChild(li);
        // alert(li.textContent);
        console.log('ReceiveMessage 111');
      
        // console.log('fire');

        if(window.messages.receiver){ 
            try{

              

                if(window.messages.receiver(sender, message)){
                    console.log('wont notify');
                    return;
                }
                else{
                   //window.messages.notify(sender, message);
                }
            }
            catch{

            } 
        }
       
    });

}


export default initSocket;
