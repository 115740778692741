
import React, { Component, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';

import './StartPost.scss';

import PostHeadList from './PostHeadList';
import FileUpload from './FileUpload';
import { api } from '../shorten/Shorten.Api';
import { Player, BigPlayButton } from "video-react";
import Statics from '../Statics';
import Icon from '../Shared/Icon';


const  StartPost= forwardRef(({ placeholder, enableFlags, titleOnly, enableReadyContent, postId, isComment, onAdd, sendActive, activated, sendReel }, ref) => {


  useImperativeHandle(ref, () => ({
    setContentTypeToChannel: ()=> setContentType(Statics.CONTENT_TYPE.CHANNEL),
    setContentTypeToPost: ()=> {
      if(hasVideo){
        setContentType(Statics.CONTENT_TYPE.VIDEO);
      } 
      else if(hasImage){
        setContentType(Statics.CONTENT_TYPE.IMAGE);
      }
      else{
        setContentType(Statics.CONTENT_TYPE.TEXT);
      }
    }
  }));


  useEffect(() => {  
    setContentType(getContentType());
  }, [sendReel]); 


  const getContentType = ()=>{
    console.log('getContentType' , sendReel);
    if(sendReel){
      return Statics.CONTENT_TYPE.CHANNEL;
    }
    else{
      if(hasImage){
        return Statics.CONTENT_TYPE.IMAGE;
      }
      if(hasVideo){
        return Statics.CONTENT_TYPE.VIDEO;
      }
      return  Statics.CONTENT_TYPE.TEXT;
    } 
  }

  const mediaUploader = useRef(null)
  const mediaUploaderFileImage = useRef(null)
  const mediaUploaderFileVideo = useRef(null)

  const [hasVideo, setHasVideo] = useState(false);
  const [hasImage, setHasImage] = useState(false);
  const [sending, setSending] = useState(activated);
  const [posted, setPosted] = useState(false);
  const [nsfw, setNSFW] = useState(false);
  const [spoiler, setSpoiler] = useState(false);
  const [contentType, setContentType] = useState(getContentType());
//  const [content, setContent] = useState(null);
  const [isSendActive, setIsSendActive] = useState(sendActive);

  const titleRef = useRef();
  const descRef = useRef();

  const [fileImage, setFileImage] = useState();
  const [fileVideo, setFileVideo] = useState();
  const [file, setFile] = useState(null);
  const [sendInProgress, setSendInProgress] = useState(false);

  function handleChange(e) {
    // debugger;
    console.log(e)

    let fileType = e.target.files[0].type;
    setFile( e.target.files[0]);

    if (fileType.includes('image')) {
      setFileImage(URL.createObjectURL(e.target.files[0]));
      setFileVideo(null);
      if(contentType!==Statics.CONTENT_TYPE.CHANNEL){
        setContentType(Statics.CONTENT_TYPE.IMAGE);
      }
      setHasImage(true);
      

    }
    else if (fileType.includes('video')) {
      setFileVideo(URL.createObjectURL(e.target.files[0]));
      setFileImage(null);
      if(contentType!==Statics.CONTENT_TYPE.CHANNEL){
        setContentType(Statics.CONTENT_TYPE.VIDEO);
      }
      setHasVideo(true);
    }

  }

  const removeSelectedFile = () => {
    setHasVideo(false);
    setHasImage(false);
    setFileImage(null);
    setFileVideo(null);
    if(contentType!==Statics.CONTENT_TYPE.CHANNEL){
      setContentType(Statics.CONTENT_TYPE.TEXT);
    }
  }
  
  const onMediaUploadBrowseClick = () => {
    mediaUploader.current.click();
  }

  const onMediaUploaderThumbnailDoubleClick = () => {
    setFileImage(null);
    setFileVideo(null);
  }



  const send = async () => {
    if(contentType==Statics.CONTENT_TYPE.CHANNEL &&  !hasVideo){
      alert('Please upload a video.')
      return;
    } 
    let data = null;

    setSendInProgress(true);
    let content = '';
    if(hasImage || hasVideo ){
      let fileUploaded = await api.uploadFile(file, hasImage?'image':'video');
      if(!fileUploaded){
        setSendInProgress(false);
        return;
      }
      else{
        content = fileUploaded.url;

      }
    }

    if (isComment) {
      data = await sendComment(content);
    }
    else {
      data = await sendPost(content);
    }
    // console.log('data', data);
    if (data) {
      titleRef.current.value = '';
      if (descRef.current) {
        descRef.current.value = '';
      }
      setNSFW(false);
      setSpoiler(false);
      setPosted(true);
    }

    if (data && onAdd) {
      data.isNew = true;
      onAdd(data);
      setSending(false);
    }

    setSendInProgress(false);
  }

 

  const sendPost = async (content) => {
    let newPost = await api.sendPost(titleRef.current.value, contentType, content, descRef.current.value, nsfw, spoiler);
    console.log('newPost', newPost);
    newPost.insertedDate = new Date(newPost.insertedDate) || new Date();
    newPost.isNew = true;
    return newPost;
  }




  const sendCommentReady = async (content, type) => {
    setSendInProgress(true);
    setSending(false);
    let newPost = await api.sendComment('', type, content, postId);
    newPost.insertedDate = new Date(newPost.insertedDate) || new Date();
    newPost.avatarUrl = window.userData.avatarUrl;
    newPost.userName = window.userData.userName;
    newPost.isNew = true;
    if (newPost && onAdd) {
      onAdd(newPost);
      setSending(false);
    }
    setSendInProgress(false);
  }


  const sendComment = async (content) => {

    let newPost = await api.sendComment(titleRef.current.value, contentType, content, postId);
    newPost.insertedDate = new Date(newPost.insertedDate) || new Date();
    newPost.avatarUrl = window.userData.avatarUrl;
    newPost.userName = window.userData.userName;
    newPost.isNew = true;
    return newPost;
  }

  let loggedIn = window.userData ? true: false;
  if(!loggedIn) return <></>

  return (

    <div className={'start-post ' + (sending ? 'open' : 'closed') + (isComment?' comment ':'') + (sendInProgress?' disabled ':' ')} onClick={() => { if (!sending) setSending(true) }}>
      
      {/* {posted && <div className='posted'></div>} */}
      <textarea className={'post-title ' + (titleOnly ? ' large ' : '')} onKeyDown={() => setIsSendActive(titleRef.current.value.trim().length > 4)} placeholder={placeholder} ref={titleRef} />


      {!titleOnly && <textarea className='post-desc' placeholder='Description' ref={descRef} />}
      {enableReadyContent &&
          <div className='post-ready'>
            <div className='post-ready-item' onClick={() => sendCommentReady('resdfasda', Statics.CONTENT_TYPE.RANDOM)}>resdfasda</div>
            <div className='post-ready-item' onClick={() => sendCommentReady('/emoji_fp.png', Statics.CONTENT_TYPE.EMOJI_FP)}><img src="/emoji_fp.png" /></div>
            <div className='post-ready-item' onClick={() => sendCommentReady('/emoji_loved.png', Statics.CONTENT_TYPE.EMOJI_LOVE)}><img src="/emoji_loved.png" /></div>
            <div className='post-ready-item' onClick={() => sendCommentReady('/emoji_lol.png', Statics.CONTENT_TYPE.EMOJI_LOL)}><img src="/emoji_lol.png" /></div>
            <div className='post-ready-item' onClick={() => sendCommentReady('/emoji_cry.png', Statics.CONTENT_TYPE.EMOJI_CRY)}><img src="/emoji_cry.png" /></div>
            <div className='post-ready-item' onClick={() => sendCommentReady('/emoji_blow.png', Statics.CONTENT_TYPE.EMOJI_BLOW)}><img src="/emoji_blow.png" /></div>
            <div className='post-ready-item' onClick={() => sendCommentReady('/emoji_angry.png', Statics.CONTENT_TYPE.EMOJI_ANGRY)}><img src="/emoji_angry.png" /></div>
          </div>}
          {(hasImage || hasVideo ) && 
      <div className='start-post-bottom'>
        {/* {enableFlags ?  
              <div className='post-flags'>  
                <div className={'post-flag '+(nsfw?' selected ':'')} onClick={()=>setNSFW(!nsfw)}>NSFW</div>
                <div className={'post-flag '+(spoiler?' selected ':'')} onClick={()=>setSpoiler(!spoiler)}>SPOILER</div>
              </div>
              :<div className='post-flags'></div>
          } */}

        

        <div className="media-uploader-thumbnail" onDoubleClick={onMediaUploaderThumbnailDoubleClick}>

          {fileImage != null &&
            <div className='file-preview' style={{ backgroundImage: `url(${fileImage})`, backgroundSize: "cover" }}>
              <div className='file-preview-remove' onClick={removeSelectedFile}>
                <Icon name="cancel" />
              </div>
            </div>
          }
          {fileVideo != null &&
           <div className='file-preview'>
            <div className='file-preview-remove' onClick={removeSelectedFile}>
              <Icon name="cancel" />
            </div>
            <Player  src={fileVideo} playsInline fluid={false} width={300} height={300} >
              <BigPlayButton position="center" />
            </Player>
            </div>
          }
        </div>


        {/* <div className='post-types'>
                <div className='post-type'><img src="/photo.png" />Add Photo</div>
                <div className='post-type'><img src="/video.png" />Add Video</div>
            </div> */}
      </div>
      }
      {/* <FileUpload /> */}
    
      <div className='start-post-send'>
        {enableFlags && !sendReel &&
          <div className='post-flags'>
            <div className={'post-flag ' + (nsfw ? ' selected ' : '')} onClick={() => setNSFW(!nsfw)}>NSFW</div>
            <div className={'post-flag ' + (spoiler ? ' selected ' : '')} onClick={() => setSpoiler(!spoiler)}>SPOILER</div>
          </div>
        }

        {!enableFlags && !sendReel &&
         <div className='post-flags'></div>
        }

        {sendReel &&  
        <div className='post-types send-reel'>
          <input type="file" onChange={handleChange} className="media-uploader" ref={mediaUploader} />
          <div className='post-type choose-reel' onClick={onMediaUploadBrowseClick} >
            <Icon name="movie" />  
            Choose Video
          </div> 
        </div>
        }

        {!sendReel && 
          <div className='post-types'>
            <input type="file" onChange={handleChange} className="media-uploader" ref={mediaUploader} />
            <div className='post-type' onClick={onMediaUploadBrowseClick} >
              <Icon name="photo_camera" /> 
              {/* <img src="/image.png" /> */}
            </div>

            {/* <div className='post-type'><img src="/video.png" /></div> */}
          </div>
        }

        {/* <div className='start-post-cancel-button'  onClick={()=>setSending(false)}><img src="/tired.png" />CANCEL</div> */}
        <div className={'start-post-send-button ' + (isSendActive ? ' active ' : '')} onClick={() => send()} >
          {/* <img src="/send.png" /> */}
           <Icon name="send" /> 
          PUBLISH
        </div>
      </div>
    </div>

  );


});


export default StartPost;
