//  import CommentItem from '../Post/CommentItem';
import EmptyList from '../Shared/EmptyList';
import Loading from '../Shared/Loading';
import PostList from '../Shared/PostList';
import { api } from '../shorten/Shorten.Api';
// import './PostList.scss'; 
 import React, { Component, useImperativeHandle, forwardRef,useState, useEffect }  from 'react';

   

const UserPostList =  forwardRef(({userId}, ref) => {

  useImperativeHandle(ref, () => ({
      addPost:addPost
  }));


  const addPost = (item) =>{ 
    setPosts([item, ...posts]);
  }


  const gotoPost = (item) =>{
    window.openPage('/post/'+item.postId);
  }


  const [posts, setPosts] = useState([]);
  const [loaded, setLoaded] = useState(false);


  useEffect(() => {
    loadPosts(); 
  }, [userId]); 

  const loadPosts =  async () => {

    let result   = await api.getUsersPosts(userId);
    
     
 
    result = result.map(r=>{
      r.insertedDate = new Date(r.insertedDate) || new Date();
      if(r.commentsOfPost && r.commentsOfPost.length>0){
        r.commentsOfPost = r.commentsOfPost.map(c=> {
          if(c){
            c.insertedDate = new Date(c.insertedDate) || new Date();
          }
          return c;
        })
      }
      return r;
    });
      setLoaded(true);
      setPosts(result);
  }


  if(!loaded) return <Loading /> ;
  if(posts.length==0) return <EmptyList />;

  return (
      <PostList posts={posts} />
  );

});

export default UserPostList;
