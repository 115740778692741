 
 import React, { Component, forwardRef, useEffect, useImperativeHandle, useRef, useState }  from 'react';
 
 import './Messages.scss';    
import { api } from '../shorten/Shorten.Api';  
import Loading from '../Shared/Loading';
import MessageItem from './MessageItem';
 
const Messages = forwardRef(({user}, ref) => {
 
 
  
    useImperativeHandle(ref, () => {
        return {
          send: send
        }
    }); 
  
  const [messages, setMessages] = useState([]);  
  const [loaded, setLoaded] = useState(false); 
  const [messageInterval, setMessageInterval] = useState(null); 
  
  useEffect(() => {
    loadMessages(); 
    // if(messageInterval){
    //   clearInterval(messageInterval); 
    // }
    // setMessageInterval( setInterval(()=>{
    //   loadMessagesInterval();
    // },2000));
  }, [user]); 
  
   

       
  const send =  (item) => { 
      setMessages([item, ...messages]); 
      document.querySelector('.messages').scrollTo({top: 0, behavior: 'auto'});
      
      
      console.log('send    11');
  }

const loadMessagesInterval =  async () => {
  
  let messagesFromDb = await api.getUsersMessages(user.userId); 
  messagesFromDb.forEach(m=>{
    m.insertedDate = new Date(m.insertedDate) || new Date();
  });
  setMessages(messagesFromDb || []);
   
}
       
  const loadMessages =  async () => {
        setLoaded(false);
        setMessages([]);

        let messagesFromDb = await api.getUsersMessages(user.userId); 
        messagesFromDb.forEach(m=>{
          m.insertedDate = new Date(m.insertedDate) || new Date();
        })
        setMessages(messagesFromDb || []);
        setLoaded(true);
  }
 
  return (
    <div className="messages">
          
      {!loaded && <Loading />} 
      {messages.map(m=> <MessageItem key={m.messageId} message={m} /> )}
    </div>
  )
   
});


export default Messages;
