import React, { Component, useImperativeHandle, forwardRef,useState, useEffect }  from 'react';
import Statics from '../Statics';
import Icon from '../Shared/Icon';

   

const MessageContent = ({message}) => {
  
 
  
  const playVideo = ()=>{
    
  }

  const getTextMessage =(content) =>{
      let str = content
          .replace(/((http:|https:)[^\s]+[\w])/g,'<a href="$1" target="_blank">$1</a>')
          .replace(/\r\n|\r|\n/g,"<br />");
      
      return <div dangerouslySetInnerHTML={{__html: str }} ></div>
  }

  return (      
          <div className='content'  >
            <div className='content-inner'  >
            {message.contentType==Statics.CONTENT_TYPE.TEXT && getTextMessage(message.content)}
                    {message.contentType==Statics.CONTENT_TYPE.IMAGE && <img src={message.content} />}
                    {message.contentType==Statics.CONTENT_TYPE.VIDEO && <video src={message.content} />}
                    {message.contentType==Statics.CONTENT_TYPE.YOUTUBE_VIDEO && 
                                                            <div className="youtube-container" onClick={()=>playVideo()}>
                                                                <img src={'https://i.ytimg.com/vi/'+message.content.replace('https://www.youtube.com/watch?v=','').replace('https://www.youtube.com/embed/','')+'/hqdefault.jpg'} />
                                                                <Icon name="sound_sampler" className="play-button" />
                                                                {/* <iframe   src={post.content} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe> */}
                                                            </div>}  
            </div>
          
          </div>
  );

};

export default MessageContent;
