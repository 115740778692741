import { useEffect, useState } from "react";
 

 

 
export class DataStore  {  


//  export const DataStore = (defaultValue) => { 
  
    id=0;
    data = {}
   constructor(defaultValue){
        this.data = { 
          value:defaultValue,
          lastUpdate: 0
        };
    }

    updaters = {};
    set= (newData)=>{  
    this.data.value = newData;
      this.data.lastUpdate = new Date();
      for(let p in this.updaters){
        this.updaters[p][1]({id:this.updaters[p][0].id, updated:this.updaters[p][0].updated+1});
      }
      // console.log('set....' , updaters);
      // updaters.forEach(u=>{
      //     u.set(u.value+1);
      // });
  }

  // static add= (newItem)=>{  
  //   data.value.push(newItem);
  //     data.lastUpdate = new Date();
  //     for(let p in updaters){
  //       updaters[p][1]({id:updaters[p][0].id, updated:updaters[p][0].updated+1});
  //     } 
  // }

  // static update= (oldItem, newItem, key)=>{  
  //     for(let i=0; i<data.value.length; i++){
  //       if(data.value[i][key]==oldItem[key]){
  //         data.value[i]=newItem;
  //       }
  //     } 
  //     data.lastUpdate = new Date();
  //     for(let p in updaters){
  //       updaters[p][1]({id:updaters[p][0].id, updated:updaters[p][0].updated+1});
  //     } 
  // }

  // static upsert = (newItem, key)=>{ 
  //     let found = false; 
  //     for(let i=0; i<data.value.length; i++){
  //       if(data.value[i][key]==newItem[key]){
  //         data.value[i]=newItem;
  //         found = true;
  //       }
  //     }
  //     if(!found){
  //       data.value.push(newItem);
  //     }
  //     data.lastUpdate = new Date();
  //     for(let p in updaters){
  //       updaters[p][1]({id:updaters[p][0].id, updated:updaters[p][0].updated+1});
  //     } 
  // }

    get = ()=>{ 
    return this.data.value;
  }

    follow = ()=>{
    
   // const [value, setValue] = ;
  //  let id = '';
    let state = useState(()=>{ return {id:this.id++, updated:0}; }); //{value, setValue}; 
  //  console.log('id', id);
  this.updaters[state[0].id] =  state;
    return this.data.value;
  } 
   
  // return {set, get, Follow, add, update, upsert};
}


export class  DataEvent  { 
   
    updaters = [];
  fire= (data)=>{  
    // console.log('updaters for event ', updaters.length);
    let result = false;
      this.updaters.forEach(u=>{
        result =  u.callback(data);
      });
      return result;
    // console.log('eevent fire ',  this.updaters.length);
  } 
  
  follow = (callback, dependencies)=>{   
    useEffect(()=>{
      this.updaters.push({callback:callback}); 
    },dependencies || []); 
    return callback;
  } 
   
   
};
 
 