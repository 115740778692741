 
 import React, { Component, useEffect, useRef, useState }  from 'react';
import UserProfile from './UserProfile';
import Helpers from '../helpers';
import Me from './Me';
 
 
function MyProfile() {

  
   if(Helpers.isMobile()){
    return <Me />
   }

  return (
     <UserProfile />
  )
   
}

export default MyProfile;
