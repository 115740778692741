 
 import React, { Component, useEffect, useRef, useState }  from 'react';
 import './Main.scss';  
import PostListingMainPage from './PostListingMainPage';
 
function Trending() { 
    return <PostListingMainPage type={'trending'} />
}

export default Trending;
