 const Statics = {
  CONTENT_TYPE : {
    TEXT: 1,
    IMAGE: 2, 
    VIDEO: 3,
    YOUTUBE_VIDEO: 4,
    CHANNEL:5,
    RANDOM:10,
    EMOJI_FP:11,
    EMOJI_LOVE:12,
    EMOJI_LOL:13,
    EMOJI_CRY:14,
    EMOJI_BLOW:15,
    EMOJI_ANGRY:16

  }
}

export  default Statics;