import React, { Component, useImperativeHandle, forwardRef,useState, useEffect }  from 'react';
import Statics from '../Statics';
import Icon from './Icon';

   

const PostContent = ({post, canClick, onViewContent}) => {
  
 
 
  const gotoPost = () =>{
    window.openPage('/post/'+post.postId, true);
  } 
 
  const getContent=(content)=>{
      var str =  (content || '')
      .replace(/((http:|https:)[^\s]+[\w])/g,'<a href="$1" target="_blank">$1</a>')
      .replace(/\r\n|\r|\n/g,"<br />");

      return <div dangerouslySetInnerHTML={{__html: str }} ></div>
  }

  const gotoPostClicked = () =>{
    if(canClick){
      gotoPost();
    }
  }

  return (      
  
    <div className={"post-item-content"} onClick={()=>gotoPostClicked()}>
                {post.title && <div className="post-item-title" onClick={()=>gotoPostClicked()}>{getContent(post.title)}</div>}
                {post.description && <div className="post-item-description" onClick={()=>gotoPostClicked()}>{getContent(post.description)}</div>}

                <div className="post-item-nsfw" onClick={onViewContent}>View NSFW content</div> 
                <div className="post-item-spoiler"  onClick={onViewContent}>View SPOILER content</div> 
                <div className="post-item-nsfw-spoiler" onClick={onViewContent}>View NSFW & SPOILER content</div>  

                <div className={"post-item-content-typed"}  onClick={()=>gotoPostClicked()}>
                    {post.contentType==Statics.CONTENT_TYPE.VIDEO &&  <Icon name="sound_sampler" className="play-button" />}

                    {post.contentType==Statics.CONTENT_TYPE.TEXT && post.content}
                    {post.contentType==Statics.CONTENT_TYPE.IMAGE && <img src={post.content} />}
                    {post.contentType==Statics.CONTENT_TYPE.VIDEO && <video src={post.content} />}
                    {post.contentType==Statics.CONTENT_TYPE.YOUTUBE_VIDEO && 
                                                            <div className="youtube-container" onClick={()=>gotoPostClicked()}>
                                                                <img src={'https://i.ytimg.com/vi/'+post.content.replace('https://www.youtube.com/watch?v=','').replace('https://www.youtube.com/embed/','')+'/hqdefault.jpg'} />
                                                                <Icon name="sound_sampler" className="play-button" />
                                                                {/* <iframe   src={post.content} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe> */}
                                                            </div>}
                </div>  
          </div>  

  );

};

export default PostContent;
