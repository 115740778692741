 
import CommentItem from '../Shared/CommentItem';
 
 import React, { Component, useImperativeHandle, forwardRef,useState, useEffect }  from 'react';
import { api } from '../shorten/Shorten.Api';
import PostListItem from '../Shared/PostListItem';
import './UserCommentList.scss';
import Loading from '../Shared/Loading';
import EmptyList from '../Shared/EmptyList';
   

const UserCommentList =  ({userId}) => {
 
 

    const gotoPost = (item) =>{
      window.openPage('/post/'+item.postId);
    }


    const [comments, setComments] = useState([]);
    const [loaded, setLoaded] = useState(false);


    useEffect(() => {
      loadData(); 
    }, [userId]); 

    const loadData =  async () => {

      let result   = await api.getUsersComments(userId);
      
      

      result = result.map(r=>{
        r.insertedDate = new Date(r.insertedDate) || new Date();
        r.commentsOfPost = r.commentsOfPost || [];
        r.commentsOfPost.forEach(c=>{
          c.insertedDate = new Date(r.insertedDate) || new Date();
        });
        return r;
      });

      setComments(result);
      setLoaded(true);
    }


  return (
    <div className="comment-list">
        {!loaded && <Loading />}
        {comments.length==0 && loaded && <EmptyList />}
 
         {comments.map(p=>  
                  <PostListItem key={p.postId} post={p} comments={p.commentsOfPost} />
              
           )}
    </div>
  );
};

export default UserCommentList;
