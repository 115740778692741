

import React from 'react';
import { api } from '../shorten/Shorten.Api';
import './AccountPopup.scss';

import Popup from './Popup';
import Loading from '../Shared/Loading';


const { Fragment, useState, useEffect, forwardRef, useRef, useImperativeHandle } = React;


const AccountPopup = forwardRef((props, ref) => {

  useImperativeHandle(ref, () => ({
    showLogin: showLogin,
    showRegister: showRegister,
    showForgotPassword: showForgotPassword


  }));

  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [showRegisterPopup, setShowRegisterPopup] = useState(false);
  const [showForgotPasswordPopup, setShowForgotPasswordPopup] = useState(false);
  const [wrongInfo, setWrongInfo] = useState(false);
  const [showWrongMessage, setShowWrongMessage] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [avatar, setAvatar] = useState('123123');



  const passwordRef = useRef();
  const emailRef = useRef();
  const nameRef = useRef();
  const countryRef = useRef();




  const showLogin = () => {
    console.log('show login');
    setShowLoginPopup(true);
    setShowRegisterPopup(false);
    setShowForgotPasswordPopup(false);
    setShowWrongMessage(false);
    setWrongInfo(false);
  };

  const showRegister = () => {
    setShowRegisterPopup(true);
    setShowLoginPopup(false);
    setShowForgotPasswordPopup(false);
    setShowWrongMessage(false);
    setWrongInfo(false);
  };

  const showForgotPassword = () => {
    setShowForgotPasswordPopup(true);
    setShowLoginPopup(false);
    setShowRegisterPopup(false);
    setShowWrongMessage(false);
    setWrongInfo(false);
  };


  const onLogin = async () => {
    console.log('onlogin');
    setShowLoading(true);

    var loginResult = await api.login(emailRef.current.value, passwordRef.current.value);

    setShowLoading(false);

    if (loginResult) {
      setShowLoginPopup(false);
      setShowWrongMessage(false);

      window.location.reload();
    }
    else {
      setWrongInfo(true);
      setShowWrongMessage(true);
      setTimeout(() => {
        setWrongInfo(false);
      }, 500);
    }
  };

  const onRegister = async () => {

    setShowLoading(true);

    var loginResult = await api.register(nameRef.current.value, emailRef.current.value, avatar, passwordRef.current.value, countryRef.current.value, (navigator.language || navigator.userLanguage));

    setShowLoading(false);

    if (loginResult) {
      setShowRegisterPopup(false);
      setShowWrongMessage(false);
    }
    else {
      setWrongInfo(true);
      setShowWrongMessage(true);
      setTimeout(() => {
        setWrongInfo(false);
      }, 500);
    }
  };

  const onForgetPassword = async () => {

    setShowLoading(true);

    var loginResult = await api.forgetPassword(nameRef.current.value, emailRef.current.value);

    setShowLoading(false);

    if (loginResult) {
      setShowForgotPasswordPopup(false);
      setShowWrongMessage(false);
    }
    else {
      setWrongInfo(true);
      setShowWrongMessage(true);
      setTimeout(() => {
        setWrongInfo(false);
      }, 500);
    }
  };

  return (

    <div>
      {showLoginPopup ? <Popup title="Login" maxWidth={'400px'} onPopupHide={() => setShowLoginPopup(false)}>

        {showLoading && <Loading />}

        <div className={'login_box' + (wrongInfo ? ' wrong ' : '') + (showLoading ? ' loading ' : '')}  >


          <div className={'wrong-message' + (showWrongMessage ? ' show ' : '')}>Wrong username or password</div>

          <div className='login_box_label'></div>
          <div className='login_box_value'>
            <input type="text" name="username" ref={emailRef} placeholder='Your e-mail address' />
          </div>

          <div className='login_box_label'> </div>
          <div className='login_box_value'>
            <input type="password" name="password" ref={passwordRef} placeholder='Your password' />
          </div>

          <input type="submit" className='login_box_button button button--red' onClick={() => onLogin()} value="Login" />
          <div className='login_box_links'>
            <a onClick={() => showForgotPassword()}>Forgot Password?</a>
            <a onClick={() => showRegister()}>Register</a>
          </div>

        </div>

      </Popup> : null}


      {showRegisterPopup ? <Popup title="Register" maxWidth={'400px'} onPopupHide={() => setShowRegisterPopup(false)}>

        <div className={'login_box' + (wrongInfo ? ' wrong ' : '') + (showLoading ? ' loading ' : '')} action="" method='POST'>
          <div className={'wrong-message' + (showWrongMessage ? ' show ' : '')}>There was an error while registering.</div>

          {/* <div className='login_box_label'>Username (Nickname):</div> */}
          <div className='login_box_value'>
            <input type="text" name="name" ref={nameRef} placeholder="Choose a Username (Nickname)" />
          </div>
          {/* 
              <div className='login_box_label'>Mobile number:</div>
              <div className='login_box_value'>
                <input type="text"  name="mobile" ref={mobileRef}  />
              </div>    */}

          <div className='login_box_label'> </div>
          <div className='login_box_value'>
            <input type="text" name="email" ref={emailRef} placeholder="Your e-mail address" />
          </div>

          <div className='login_box_label'> </div>
          <div className='login_box_value'>
            <input type="password" name="password" ref={passwordRef} placeholder="Your password" />
          </div>

          <div className='login_box_label'> </div>
          <div className='login_box_value'>
            <select type="text" name="country" ref={countryRef} placeholder='Your country'>
              {countries.map(c => <option key={c.value} value={c.value}>{c.name}</option>)}
            </select>
          </div>


          <input type="submit" className='login_box_button button button--red' onClick={() => onRegister()} value="Register" />
          <div className='login_box_links'>
            <a onClick={() => showForgotPassword()}>Forgot Password?</a>
            <a onClick={() => showLogin()}>Login</a>
          </div>

        </div>
      </Popup> : null}

      {showForgotPasswordPopup ? <Popup title="Forgot Password" maxWidth={'400px'} onPopupHide={() => setShowForgotPasswordPopup(false)}>

        <div className={'login_box' + (wrongInfo ? ' wrong ' : '') + (showLoading ? ' loading ' : '')} action="" method='POST'>
          <div className={'wrong-message' + (showWrongMessage ? ' show ' : '')}>There was an error.</div>

          <div className='login_box_label'>Full Name:</div>
          <div className='login_box_value'>
            <input type="text" name="name" ref={nameRef} />
          </div>

          <div className='login_box_label'>Email:</div>
          <div className='login_box_value'>
            <input type="text" name="email" ref={emailRef} />
          </div>

          <input type="submit" className='login_box_button button button--red' onClick={() => onForgetPassword()} value="Send E-mail" />
          <div className='login_box_links'>
            <a onClick={() => showLogin()}>Login</a>
            <a onClick={() => showRegister()}>Register</a>
          </div>

        </div>

      </Popup> : null}

    </div>
  );
});


export default AccountPopup;


const countries = [
  { name: 'Choose your countrry', value: 'none' },
  { name: 'United States Of America', value: 'usa' },
  { name: 'United Kingdom', value: 'uk' },
  { name: 'Germany', value: 'de' },
  { name: 'France', value: 'fr' },
  { name: 'China', value: 'ch' },
  { name: 'India', value: 'in' },
  { name: 'Turkey', value: 'tr' },
  { name: 'Canada', value: 'ca' },
  { name: 'Russia', value: 'ru' },
  { name: 'Japan', value: 'jp' },
  { name: 'Brazil', value: 'br' },
  { name: 'United Arab Emirates', value: 'uae' },
  { name: 'Singapore', value: 'sg' },

]