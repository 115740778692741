 
 import React, { Component, useEffect, useRef, useState }  from 'react';
 
 import './Main.scss';  
import PostHeadList from '../Home/PostHeadList';
import CommentList from './CommentList';
import StartPost from '../Home/StartPost';
import { useParams } from 'react-router-dom';
import { api } from '../shorten/Shorten.Api';
import Statics from '../Statics';
import Loading from '../Shared/Loading';
import Menu from '../Shared/Menu';
import Icon from '../Shared/Icon';

 window.some_func = function(){
    alert('a');
 }
function Main() {

  const routeParams = useParams();

  const [comments, setComments] = useState([]); 
  const [post, setPost] = useState({}); 
  const [loaded, setLoaded] = useState(false);
  const [likeCount, setLikeCount] = useState(0);

  const postId  =  routeParams.id;
 
//   let comments = [
//     {
//         CommentId:'1', 
//         PostId:'1',
//         Description:'gfycat.com/dearac... Game name is Bloodborne.',
//         ContentType:'',
//         Content:'https://thumbs.gfycat.com/DearAccurateCottonmouth-mobile.jpg',
//         InsertedDate: new Date(),
//         Sender:{
//           UserId:'1',
//           UserName:'Ersin Ceylan',
//           AvatarUrl:'/logo.png'
//         }
//     },

//     {
//       CommentId:'2', 
//       PostId:'1',
//       Description:'gfycat.com/dearac... Game name is Bloodborne.',
//       ContentType:'',
//       Content:'https://thumbs.gfycat.com/DearAccurateCottonmouth-mobile.jpg',
//       InsertedDate: new Date(),
//       Sender:{
//         UserId:'1',
//         UserName:'Ersin Ceylan',
//         AvatarUrl:'/logo.png'
//       }
//   },
//     {
//       CommentId:'3', 
//       PostId:'1',
//       Description:'gfycat.com/dearac... Game name is Bloodborne.',
//       ContentType:'',
//       Content:'https://thumbs.gfycat.com/DearAccurateCottonmouth-mobile.jpg',
//       InsertedDate: new Date(),
//       Sender:{
//         UserId:'1',
//         UserName:'Ersin Ceylan',
//         AvatarUrl:'/logo.png'
//       }
//   },
//   {
//     CommentId:'4', 
//     PostId:'1',
//     Description:'gfycat.com/dearac... Game name is Bloodborne.',
//     ContentType:'',
//     Content:'https://thumbs.gfycat.com/DearAccurateCottonmouth-mobile.jpg',
//     InsertedDate: new Date(),
//     Sender:{
//       UserId:'1',
//       UserName:'Ersin Ceylan',
//       AvatarUrl:'/logo.png'
//     }
// }

//   ]


//   let posts = [
//     {
//       PostId:'1', 
//       Title:'all enemies are hard, but this is one specially hard',
//       Description:'gfycat.com/dearac... Game name is Bloodborne.',
//       ContentType:'image',
//       Content:'https://thumbs.gfycat.com/DearAccurateCottonmouth-mobile.jpg',
//       NSFW:false,
//       Spoiler:false,
//       InsertedDate: new Date(),
//       Sender:{
//         UserId:'1',
//         UserName:'Ersin Ceylan',
//         AvatarUrl:'/logo.png'
//       }

//     },

//     { 
//       PostId:'2', 
//       Title:'all enemies are hard, but this is one specially hard',
//       Description:'gfycat.com/dearac... Game name is Bloodborne.',
//       ContentType:'image',
//       Content:'https://thumbs.gfycat.com/DearAccurateCottonmouth-mobile.jpg',
//       NSFW:false,
//       Spoiler:false,
//       InsertedDate: new Date(),
//       Sender:{
//         UserId:'1',
//         UserName:'Ersin Ceylan',
//         AvatarUrl:'/logo.png'
//       }
//     }
//   ];

 

  const commentAdded = (comment) =>{

    setComments([comment, ...comments]);
    
    post.commentCount++;
    setPost(post);

  }




  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});

    setLoaded(false);
    loadPosts(); 
  }, [postId]); 
  
  
  const loadPosts =  async () => {
      let postItem = await api.getPost(postId); 
      let postComments = await api.getComments(postId) || []; 

      postItem.insertedDate = new Date(postItem.insertedDate) || new Date();
      
      postComments = postComments.map(c=> {
        if(c){
          c.insertedDate = new Date(c.insertedDate) || new Date();
        }
        return c;
      });
      
      setLoaded(true);
      setPost(postItem);
      setComments(postComments.reverse());
      setLikeCount(postItem.likeCount);
     
  }
 
  const gotoProfile=(userId)=>{ 
    window.openPage('/profile/'+userId); 
}


  const getContent=(content)=>{
      var str =  (content || '')
      .replace(/((http:|https:)[^\s]+[\w])/g,'<a href="$1" target="_blank">$1</a>')
      .replace(/\r\n|\r|\n/g,"<br />");

    return <div dangerouslySetInnerHTML={{__html: str }} ></div>
  }

  const like = () =>{ 
    if(post.uLiked){
      api.unlikePost(postId);
      post.uLiked = false;
      setPost({...post});
      setLikeCount(likeCount-1);
    }
    else{
      api.likePost(postId);
      post.uLiked = true;
      setPost({...post});
      setLikeCount(likeCount+1);
    }
    
  }


  let loggedIn = window.userData ? true: false;
 
  return (
    <div className="page post">

       
        <div className='main-left'>
            <Menu />  
        </div>  
        <div className='main-title'>
            <div className='back-button' onClick={()=>{ window.history.back(); }}>
            <Icon name="arrow_back_ios"   /> GO BACK</div>
        </div>  
        <div className='main-center'>
            {!loaded &&  <div   className="post-item-empty"> <Loading /> </div>}
            {loaded && 
                          <div   className="post-item"> 
                                <div className="post-item-sender" onClick={()=>gotoProfile(post.insertedBy)}>
                                    <img src={post.avatarUrl || '/guest.png'} />
                                    <span className='name'>{post.userName}</span> 
                                    {/* <span className='label'> poosted at </span> */}
                                    <span className='time'>{post.insertedDate?post.insertedDate.toUTCString().replace(' GMT',''):''}</span>
                                </div>
                                <div className="post-item-title">{post.title}</div>
                                <div className="post-item-description">{getContent(post.description)}</div>
                                
                                <div className="post-item-content">
                                  {post.contentType==Statics.CONTENT_TYPE.TEXT && post.content}
                                  {post.contentType==Statics.CONTENT_TYPE.IMAGE && <img src={post.content} />}
                                  {post.contentType==Statics.CONTENT_TYPE.VIDEO && <video src={post.content} controls />}
                                  {post.contentType==Statics.CONTENT_TYPE.YOUTUBE_VIDEO && <div className="youtube-container">
                                                                                                <iframe   src={post.content} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                                                                            </div>}
                                  

                                </div>
                                {!loggedIn &&   <div className="post-item-bottom"></div>}
                                {loggedIn &&   <div className="post-item-bottom">
                                  <div className={"post-item-like "+(post.uLiked?' liked ':'')} onClick={like}>
                                     <Icon name="thumb_up" filled={post.uLiked} /> 
                                    {(post.uLiked?'You Liked':'Like it')} ({likeCount})
                                  </div>
                                  <div className="post-item-comment">
                                    <Icon name="chat_bubble" /> 
                                    Comment ({post.commentCount})
                                  </div> 
                                </div>}
                        </div>   

              }
              <StartPost enableReadyContent={true} titleOnly={true} placeholder="Start commenting" postId={routeParams.id} isComment={true} onAdd={commentAdded} />
                    
              {loaded  && <CommentList comments={comments}  /> }
              <div className="post-placeholder"> </div>
        </div>
        <div className='main-right'>
            <PostHeadList  />
        </div>
 
 
    </div>
  );
}

export default Main;
