 import { api } from '../shorten/Shorten.Api';
import './PostHeadList.scss'; 
 import React, { Component, useImperativeHandle, forwardRef,useState, useEffect }  from 'react';

   

const PostHeadList =  ({}) => {
 
  const gotoPost = (item) =>{
    window.openPage('/post/'+item.postId);
  }

  const [posts, setPosts] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(12);

  useEffect(() => { 
    loadNewPosts();
    
    window.postAnimateCounter = 12;
    if(window.postAnimateInterval){
      clearInterval(window.postAnimateInterval);
      window.postAnimateCounter = 12;
    }
    window.postAnimateInterval =  setInterval(()=>{
      window.postAnimateCounter++;
      console.log('currentIndex');
      if(window.postAnimateCounter<12){
        window.postAnimateCounter = 12; 
      } 
      if(window.postAnimateCounter>40){
        window.postAnimateCounter = 0; 
      } 
      setCurrentIndex(window.postAnimateCounter);
         
    },4000);

  }, []); 

  const loadNewPosts2 =  async () => {
    let result = await fetch('https://resdfapi.losmedia.com/Posts/RecentPosts');
    result = await result.json();
    console.log('resss', result);
    result = result.map(r=>{
      r.insertedDate = new Date(r.insertedDate) || new Date();
      return r;
    })
    setPosts(result);
}



// useEffect(() => {
//   loadPosts(); 
// }, [type]); 


const renderPosts =    (posts) => {
    return posts.map(r=>{
      r.insertedDate = new Date(r.insertedDate) || new Date();
      if(r.commentsOfPost && r.commentsOfPost.length>0){
        r.commentsOfPost = r.commentsOfPost.map(c=> {
          if(c){
            c.insertedDate = new Date(c.insertedDate) || new Date();
          }
          return c;
        })
      }
      return r;
    });
}

const loadNewPosts =  async () => {

  
    try{
      console.log('loading from local');
      let lastData = JSON.parse(localStorage['post_list_new']);
      if(lastData && lastData.length>0){
        setPosts(renderPosts(lastData));
      }
      
    }
    catch(ex){

    }
 
    let result = await api.getRecentPosts(0);
   

    result = renderPosts(result);

    let jsonData =  JSON.stringify( result ); 
    if(localStorage['post_list_new']==jsonData){

    }
    else{
      console.log('pushing to local');
      setPosts(result);
      localStorage['post_list_new'] = jsonData;
    }

   
}


const getContent=(content)=>{
  var str = (content || '')
  .replace(/((http:|https:)[^\s]+[\w])/g,'<a>$1</a>')
  .replace(/\r\n|\r|\n/g,"");

return <div dangerouslySetInnerHTML={{__html: str }} ></div>
}


  const getListItems = () =>{
    return posts.map((i,ind)=> 
          <div key={i.postId} className={"post-head-item " + (currentIndex>ind?'' : ' hide ')} onClick={()=>gotoPost(i)}> 
            <div className="post-item-title">{i.title}</div>
            {/* <div className="post-item-description">{getContent(i.description)}</div>  */}
            <div className="post-item-date">{i.insertedDate.toUTCString().replace(' GMT','')}</div>
          </div> 
    ).reverse();
  }
  

  return (
    <div className="post-head-list">
          <div className="post-head-list-title">What is going on</div>
          <div className='post-head-list-content'>
          {getListItems()}
         </div>
    </div>
  );
};

export default PostHeadList;
