 
 import React, { Component, useEffect, useRef, useState }  from 'react';
 import './Main.scss';  
import PostListingMainPage from './PostListingMainPage';
import StartPost from './StartPost';
import Logo from '../Shared/Logo';
import Icon from '../Shared/Icon';
 
function Send() { 

    const startPostRef = useRef();

    const addPosts = (post) => {
       alert('posted');
    }


    const sendReelClicked = (post) => {
        window.openPage('/send/reel');
        //startPostRef.current.setContentTypeToChannel(); 
     }
     const sendPostClicked = (post) => {
        window.openPage('/send/post');
       // startPostRef.current.setContentTypeToPost();
      }

    var activePage = window.location.pathname.split('/')[2] =='post'?'post':'reel';

    

    return (
    <div className="page home">

            <div className='main-title'>
                {/* <Logo />  */}

                {/* <div className='main-title-text-mobile'>
                    Post something 
                </div>
                 */}

                <div className='main-title-text-mobile'> 

                    <div className='main-tabs'>
                        <div className={'main-tab '+  (activePage=='post'?' selected ':'')} onClick={sendPostClicked}>Post</div>
                        <div className={'main-tab '+  (activePage=='reel'?' selected ':'')} onClick={sendReelClicked}>Reel</div>
                        {/* <div className={'main-tab '+  (activePage=='new'?' selected ':'')} onClick={()=>{ window.openPage('/new') }}>New</div> */}
                    </div>
                    
                </div>
               
                {/* <div className='main-title-button'>
                    <Icon name="workspaces" filled={true} />
                </div> */}


                

            </div> 
            <div className='main-center'> 

                <StartPost ref={startPostRef} placeholder="Post title" enableFlags={true} sendActive={true} sendReel={activePage=='reel'} activated={true} onAdd={addPosts} />
            </div>
            

        </div>
    );

     
}

export default Send;
