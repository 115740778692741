import React from 'react'; 

function Icon(props) {
   
  return (  
      <i className={"font-icon material-symbols-outlined " + (props.className || '') + (props.filled ? ' filled ':'')}  style={props.style}>{props.name}</i>
  );
}
 


 

export default Icon;
