 
 import React, { Component, useEffect, useRef, useState }  from 'react';
 
 import './EditProfile.scss';  
import PostHeadList from '../Home/PostHeadList';
import UserCommentList from './UserCommentList';
import StartPost from '../Home/StartPost';
import { useParams } from 'react-router-dom';
import { api } from '../shorten/Shorten.Api';
import PostList from '../Home/MainPostList';
import Menu from '../Shared/Menu';
import MainPostList from '../Home/MainPostList';
import UserPostList from './UserPostList';
import { Tab, Tabs } from '../Shared/Tab';
import SuggestedProfiles from './SuggestedProfiles';
import Follows from './Follows';
import CountrySelect from '../Shared/CountrySelect';
import Icon from '../Shared/Icon';

 window.some_func = function(){
    alert('a');
 }
function EditProfile() {

  const routeParams = useParams();
  
  const [user, setUser] = useState(null);  
  const [isMe, setIsMe] = useState(false); 
 
  const [following, setFoollowing] = useState(false); 

 const postListRef = useRef();
 const [fileImage, setFileImage] = useState();
 const [file, setFile] = useState();


 const  mediaUploader = useRef(null);

 const onMediaUploadBrowseClick = () => {
  mediaUploader.current.click();
}
 

 const addPosts = (post) =>{
  postListRef.current.addPost(post);
}

  useEffect(() => {
    loadPosts(); 
  }, []); 
  
  
  
  const setUserData = (userToShow) => {

    userToShow.level = 'Member';
    
    switch(userToShow.roleId){
      case 1: userToShow.level = 'Member';
      case 2: userToShow.level = 'Pro';
      case 3: userToShow.level = 'Expert';
      case 4: userToShow.level = 'Partner';
    }
 
    setFoollowing(userToShow.uFollowing);
    setUser(userToShow);
  }
       
  const loadPosts =  async () => {
       
      let userToShow = {};
      if(routeParams.id && routeParams.id!=(window.userData || {}).userId){
        userToShow = await api.getUser(routeParams.id);
        setUserData(userToShow);
      }
      else{ 
        setUserData((window.userData || {}));
        setIsMe(true);
      } 
  }
 


  function handleChange(e) { 

    if(!e.target.files[0]){
      return ;
    }
    let fileType = e.target.files[0].type;
    setFile( e.target.files[0]);

    if (fileType.includes('image')) {
      let avatarUrl = URL.createObjectURL(e.target.files[0]);
      setFileImage(avatarUrl); 
      user.avatarUrl = avatarUrl;
      setUser({...user});
      send();
    }

  }




  const send = async () => {
      let fileUploaded = await api.uploadFile(file, 'image');
      if(!fileUploaded){
        return;
      }
      else{
        user.avatarUrl =  fileUploaded.url;
        setUser({...user});
      }
  } 


const goBack = () =>{ 
  window.openPage('/myprofile');
}

const saveProfile = () =>{ 
  api.saveUser(user).then((res)=>{
    if(res){
      window.openPage('/myprofile');
    }
  });
}

 
  return (
    <div className="page profile">
          
        <div className='main-title'>
            <div className='main-title-text'>
            Edit Profile
            </div>
            <div className='main-title-text-mobile'>
              <div className='main-title-back-button' onClick={()=>goBack(null)}>
                    <Icon name="arrow_back_ios" filled={true} />
                </div> 
                Edit Profile
            </div>  
        </div>  

        
        <div className='main-left'>
            <Menu />  
        </div>  
        <div className='main-center'>
              {user && 
             
              <div className='edit-profile'> 

                  <div className='edit-item '>
                      
                      <div className='avatar' onClick={onMediaUploadBrowseClick} style={{backgroundImage:'url('+(user.avatarUrl  || '/guest.png')+')'}} >
                      <Icon name="photo_camera" />
                      </div>
                      <input type="file" onChange={handleChange} className="media-uploader" ref={mediaUploader} />

                  </div>
                  <div className='edit-item name'>
                    <div className='edit-item-label'>Username:</div>
                    <input type="text" defaultValue={user.userName} onChange={(e)=> setUser({...user, ...{userName:e.target.value} }) }/>
                  </div>
                  <div className='edit-item email'>
                    <div className='edit-item-label'>E-mail (can't change):</div>
                    <input type="text" disabled={true} defaultValue={user.email} onChange={(e)=> setUser({...user, ...{email:e.target.value} }) }/>
                  </div>
                  <div className='edit-item fullname'>
                    <div className='edit-item-label'>Your full name:</div>
                    <input type="text" defaultValue={user.fullName} onChange={(e)=> setUser({...user, ...{fullName:e.target.value} }) }/>
                  </div> 
                  <div className='edit-item country'>
                    <div className='edit-item-label'>Your country:</div>
                     <CountrySelect value={user.country} onChange={(e)=> setUser({...user, ...{country:e.target.value} }) } />
                  </div>
                  <br/>
                  <div className='edit-item button'>
                    <input type="button" className='button save-button' value="Save" onClick={saveProfile} />
                  </div>
                  
              </div>    
            }
            
        </div>
        <div className='main-right'>
          {user && <SuggestedProfiles userId={user.userId} />}
        </div>

    </div>
  )
   
}

export default EditProfile;
