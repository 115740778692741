import { DataEvent, DataStore } from "../Shared/Store"; 

 export class ChatStore  {  

    static sessions = new DataStore([]);
   
    static currentMessages = new DataStore([]);
    static currentSession = new DataStore(null);
       
    static onNewMessage = new DataEvent();  
    static onSendMessage = new DataEvent(); 


};
   



    
 