 
 import React, { Component, useEffect, useRef, useState }  from 'react';
 
 import './Me.scss';  
import PostHeadList from '../Home/PostHeadList';
import UserCommentList from './UserCommentList';
import StartPost from '../Home/StartPost';
import { useParams } from 'react-router-dom';
import { api } from '../shorten/Shorten.Api';
import PostList from '../Home/MainPostList';
import Menu from '../Shared/Menu';
import MainPostList from '../Home/MainPostList';
import UserPostList from './UserPostList';
import { Tab, Tabs } from '../Shared/Tab';
import SuggestedProfiles from './SuggestedProfiles';
import Follows from './Follows';
import Icon from '../Shared/Icon';
import Logo from '../Shared/Logo';

 window.some_func = function(){
    alert('a');
 }
function Me() {

  const routeParams = useParams();
  
  const [user, setUser] = useState(null);  
  const [isMe, setIsMe] = useState(false); 
 
  const [following, setFoollowing] = useState(false); 

 const postListRef = useRef();



 const addPosts = (post) =>{
  postListRef.current.addPost(post);
}

  if(!window.userData){
    window.openPage('/home');
  }

  useEffect(() => {
    loadPosts(); 
  }, []); 
  
  
  
  const setUserData = (userToShow) => {

    userToShow.level = 'Member';
    
    switch(userToShow.roleId){
      case 1: userToShow.level = 'Member';
      case 2: userToShow.level = 'Pro';
      case 3: userToShow.level = 'Expert';
      case 4: userToShow.level = 'Partner';
    }
 
    setFoollowing(userToShow.uFollowing);
    setUser(userToShow);
  }
       
  const loadPosts =  async () => {
       
      let userToShow = {};
      if(routeParams.id && routeParams.id!=(window.userData || {}).userId){
        userToShow = await api.getUser(routeParams.id);
        setUserData(userToShow);
      }
      else{ 
        setUserData((window.userData || {}));
        setIsMe(true);
      } 
  }

  // const unfollowUser = () =>{
  //   setFoollowing(false); 
  //   user.following = false;
  //   setUser(user);
  //   api.unfollow(user.userId);
  // }

  // const followUser = () => {
  //   setFoollowing(true); 
  //   user.following = true;
  //   setUser(user);
  //   api.follow(user.userId);
  // }


const onLogoutClicked = () =>{
  if(window.confirm('Are you suure to logout?')){
    localStorage['user'] = null;
    localStorage['userdata'] = null;
    localStorage['token'] = null;
    window.location.href='/trending';
  }
}

if(!user){
  return <></>
}

   
  // let getTabUrl = (subPage) => (isMe ? '/myprofile/'+subPage:'/profile/'+user.userId+'/'+subPage);
  // let loggedIn = window.userData ? true: false;
   
  return (
    <div className="page profile">
          
        <div className='main-title'>
            {/* <Logo /> */}
            <div className='main-title-text-mobile'>
              Me
            </div>
        </div>  

       
        <div className='main-center'>
             
              <div className='me-top'> 
                  <div className='avatar'><img src={user.avatarUrl || '/guest.png'} /></div>
                  <div className='name'>@{user.userName}</div>
                  <div className='stats'> 
                    <div className='stats-line'>RESDF Score: {user.score}</div>
                    <div className='stats-line'>Level: {user.level}</div> 
                  </div>
              </div>   

              {/* <div className='list-item' onClick={()=>{ window.openPage('mywallet') }}>
                <div className='list-item-text'>My Wallet</div> <Icon name="chevron_right"/>
              </div>      */}

              <div className='list-item' onClick={()=>{ window.openPage('mypost') }}>
                <div className='list-item-text'>My Posts</div> <Icon name="chevron_right"/>
              </div>     
              
              <div className='list-item' onClick={()=>{ window.openPage('mycomment') }}>
                <div className='list-item-text'> My Comments</div> <Icon name="chevron_right"/>
              </div>     
    

              <div className='list-item' onClick={()=>{ window.openPage('myfollower') }}>
                <div className='list-item-text'> My Followers</div> <Icon name="chevron_right"/>
              </div> 

              <div className='list-item' onClick={()=>{ window.openPage('myfollowing') }}>
                <div className='list-item-text'> Following</div> <Icon name="chevron_right"/>
              </div>     
              <div className='list-item'>  </div>     
             
            <br/>
              <div className='list-item' onClick={()=>{ window.openPage('/profile/edit') }}>
                <div className='list-item-text'>Edit profile</div> <Icon name="chevron_right"/>
              </div>   
              <div className='list-item'>  </div>     
              
            <br/> 
              <div className='list-item' onClick={onLogoutClicked}>
                <div className='list-item-text'>Logout</div> <Icon name="chevron_right"/>
              </div>    
              <div className='list-item'>  </div>     
             
              
            
        </div> 

    </div>
  )
   
}

export default Me;
