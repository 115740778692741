 
 import React, { Component, useEffect, useRef, useState }  from 'react';
 
 import './MessageSessionItem.scss';   
 
function MessageSessionItem({user,  onOpenChat, selectedSession}) {
  

  const openChat=(user)=>{ 
    onOpenChat(user); 
  }

  return ( <div className={'message-user-item ' +((selectedSession || {}).userId==user.userId?' selected ':'')} key={user.userName}  onClick={()=>openChat(user)}>
              <div className='avatar'><img src={user.avatarUrl || '/guest.png'}  /></div>
              <div className='name' >@{user.userName}</div>
              <div className='level'  >{user.content}</div>
              {user.notify && <div className='notify'  >{user.notify}</div> }
             
            </div>  
  );
}

export default MessageSessionItem;
