
import { Link, useLocation, useParams } from 'react-router-dom';
import './MobileMenu.scss';
import Icon from './Icon';



const MenuItem = (props) => {
  const location = useLocation();
  var isActive = location.pathname.split('/')[1] === props.to.split('/')[1];
  if (isActive && props.dontSelect) {
    isActive = false;
  }
  let disabled =  props.disabled;



  var className = isActive ? 'selected' : '';
  let newProps = { ...props };
  delete newProps.dontSelect;
  delete newProps.disabled;

  if(disabled){
    newProps.to='';
    isActive = false;
  }

  return (
    <Link   {...newProps} className={'left-menu-item ' + (isActive ? ' selected ' : '') + (disabled ? ' disabled ':'')} onClick={() => {
      window.mainPageOpening(props.to);
    }}>
      {props.children}
    </Link>
  );
}


const onLogoutClicked = () =>{
    if(window.confirm('Are you suure to logout?')){
      localStorage['user'] = null;
      localStorage['userdata'] = null;
      localStorage['token'] = null;
      window.location.reload();
    }
}


function MobileMenu() {

  let params = useParams();
  console.log(params);
  let isMe = (!params.id || window.userData.userId == params.id);

  let me =  window.userData;
 
  var activePage = window.location.pathname.split('/')[1];
  if(!me && (activePage=='trending' || activePage=='new' || activePage=='' || activePage=='post')){
    console.log('active', activePage); 
  } 
  else{
     if(!me ){
        activePage = 'trending';
        window.openPage('/trending');
     }
  }

  return ( 
    <div className={'mobile-menu ' + (me?'':' disabled ')}>
        <div className={'mobile-menu-item' + (activePage=='home' || activePage=='trending' || activePage=='new'?' selected ':'')} onClick={()=>{ window.openPage('/home') }}>
            <Icon name="home" />
            <div className='mobile-menu-item-text'>HOME</div> 
        </div>


        <div className={'mobile-menu-item' + (activePage=='channels'?' selected ':'')} onClick={()=>{ window.openPage('/channels') }}>
            <Icon name="movie" />
            <div className='mobile-menu-item-text'>CHANNEL</div> 
        </div>


        <div className={'mobile-menu-item' + (activePage=='send'?' selected ':'')} onClick={()=>{ window.openPage('/send') }}>
            <Icon name="send" />
            <div className='mobile-menu-item-text'>START POST</div> 
        </div>

        <div className={'mobile-menu-item' + (activePage=='messages'?' selected ':'')} onClick={()=>{ window.openPage('/messages') }}>
            <Icon name="forum" />
            <div className='mobile-menu-item-text'>CHAT</div> 
        </div>
 

  
        <div className={'mobile-menu-item' + (activePage=='myprofile'?' selected ':'')} onClick={()=>{ window.openPage('/myprofile') }}>
            <Icon name="person" />
            <div className='mobile-menu-item-text'>ME</div> 
        </div>
   
    </div> 
  );
}

export default MobileMenu;
