 
 import React, { Component, useEffect, useRef, useState }  from 'react';
 
 import './UserDetail.scss';  
import PostHeadList from '../Home/PostHeadList';
import UserCommentList from './UserCommentList';
import StartPost from '../Home/StartPost';
import { useParams } from 'react-router-dom';
import { api } from '../shorten/Shorten.Api';
import PostList from '../Home/MainPostList';
import Menu from '../Shared/Menu';
import MainPostList from '../Home/MainPostList';
import UserPostList from './UserPostList';
import { Tab, Tabs } from '../Shared/Tab';
import SuggestedProfiles from './SuggestedProfiles';
import Follows from './Follows';
import Icon from '../Shared/Icon';
import Logo from '../Shared/Logo';

 window.some_func = function(){
    alert('a');
 }
function UserDetail({type}) {

  const routeParams = useParams();
  
  const [user, setUser] = useState(null);  
  const [isMe, setIsMe] = useState(false); 
 
  const [following, setFoollowing] = useState(false); 

 const postListRef = useRef();



 const addPosts = (post) =>{
  postListRef.current.addPost(post);
}

  if(!window.userData){
    window.openPage('/home');
  }

  useEffect(() => {
    loadPosts(); 
  }, []); 
  
  
  
  const setUserData = (userToShow) => {

    userToShow.level = 'Member';
    
    switch(userToShow.roleId){
      case 1: userToShow.level = 'Member';
      case 2: userToShow.level = 'Pro';
      case 3: userToShow.level = 'Expert';
      case 4: userToShow.level = 'Partner';
    }
 
    setFoollowing(userToShow.uFollowing);
    setUser(userToShow);
  }
       
  const loadPosts =  async () => {
       
      let userToShow = {};
      if(routeParams.id && routeParams.id!=(window.userData || {}).userId){
        userToShow = await api.getUser(routeParams.id);
        setUserData(userToShow);
      }
      else{ 
        setUserData((window.userData || {}));
        setIsMe(true);
      } 
  }
 


const goBack = () =>{
  
  window.openPage('/myprofile');
}

if(!user){
  return <></>
}

   
  // let getTabUrl = (subPage) => (isMe ? '/myprofile/'+subPage:'/profile/'+user.userId+'/'+subPage);
  // let loggedIn = window.userData ? true: false;
   
  return (
    <div className="page profile">
          
        <div className='main-title'>
          
          <div className='main-title-text-mobile'>

           <div className='main-title-back-button' onClick={()=>goBack(null)}>
                <Icon name="arrow_back_ios" filled={true} />
            </div> 
            My
            {type=='post' && ' posts'}
            {type=='comment' && ' comments'}
            {type=='follower' && ' followers'}
            {type=='following' && ' following'}
          </div>
          
          <div className='main-title-button'>
              <Icon name="workspaces" filled={true} />
          </div>  

        </div>  

       
        <div className='main-center'>
             

        {type=='post' && <UserPostList userId={user.userId} ref={postListRef} />}
         {type=='comment' && <UserCommentList userId={user.userId}  />}
         {type=='follower' && <Follows  type="follower" userId={user.userId}  />}
         {type=='following' &&  <><Follows  type="following" userId={user.userId}  /></>}
            
        </div> 

    </div>
  )
   
}

export default UserDetail;
