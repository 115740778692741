import React, { Component, useRef, useState, forwardRef, useImperativeHandle, useEffect }  from 'react';
 

function NotificationListItem({image, title, text, onClick}) {
  
    return <div className="notification-list-item">  
              {image && <div className="image" style={{backgroundImage:'url('+image+')'}}  onClick={()=>onClick()}></div>}
              <div className="title"  onClick={()=>onClick()}>   
                {title}
              </div>
              <div className="desc">   
                {text}
              </div>  
          </div>  
  
}

 
export default NotificationListItem;
