 import CommentItem from '../Shared/CommentItem';
import './CommentList.scss'; 
 import React, { Component, useImperativeHandle, forwardRef,useState }  from 'react';

   

const CommentList =  ({comments}) => {
 
  return (
    <div className="comment-list">
         {comments.map(i=> 
          <CommentItem key={i.commentId} comment={i} />
              //  <div key={i.CommentId} className="comment-item">   
              //     <div className="comment-item-description">{i.Description}</div>
              //     {i.ContentType && 
              //     <div className="comment-item-content">
              //       {i.ContentType=='image' && <img src={i.Content} />}
              //       {i.ContentType=='video' && <video src={i.Content} />} 
              //     </div> 
              //     } 
              //     <div className="comment-item-sender">
              //         <img src={i.Sender.AvatarUrl} />
              //         <span className='name'>{i.Sender.UserName}</span>  
              //         <span className='label'> poosted at </span> 
              //         <span className='time'>{i.InsertedDate.toUTCString().replace(' GMT','')}</span>
              //     </div>
              //  </div> 
         )}
    </div>
  );
};

export default CommentList;
