 import CommentItem from './CommentItem';
import { api } from '../shorten/Shorten.Api';
import PostContent from './PostContent';
import './PostListItem.scss'; 
 import React, { Component, useImperativeHandle, forwardRef,useState, useEffect }  from 'react';
import Icon from './Icon';

   

const PostListItem =  forwardRef(({post, comments}, ref) => {

  const [viewContent, setViewContent] = useState((!post.nsfw && !post.spoiler));
  const [liked, setLiked] = useState(post.uLiked);
  const [likeCount, setLikeCount] = useState(post.likeCount);
  
  useEffect(() => {
    setViewContent((!post.nsfw && !post.spoiler));
    setLiked(post.uLiked);
    setLikeCount(post.likeCount);
  }, [post]); 


  const gotoUserProfile=(userId)=>{ 
      window.openPage('/profile/'+userId, true);  
  }
 
  const like = () =>{ 
    if(liked){
      api.unlikePost(post.postId);
      setLiked(false);
      setLikeCount(likeCount-1);
    }
    else{
      api.likePost(post.postId);
      setLiked(true);
      setLikeCount(likeCount+1);
    }
    
  }



  let loggedIn = window.userData ? true: false;
 

  return ( 
               <div key={post.postId} className={'post-list-item ' + (post.nsfw?' nsfw ':'') + (post.isNew?' --is-new ':' ') + (post.spoiler?' spoiler ':'') + (viewContent?' visible ':'') } > 
                  <div className="post-item-sender" onClick={()=>gotoUserProfile(post.insertedBy)}>
                      <img src={post.avatarUrl || '/guest.png'} />
                      <span className='name'>{post.userName}</span>  
                      <span className='time'>{(post.insertedDate).toUTCString().replace(' GMT','')}</span>
                  </div>
                   <PostContent post={post} canClick={true} onViewContent={()=>{   setViewContent(true)}} />
                  
                   {!loggedIn && <div className="post-item-bottom"></div> }
                  {loggedIn && <div className="post-item-bottom">
                    <div className={"post-item-like "+(liked?' liked ':'')} onClick={like}>
                      {/* <img src="/like.png" /> */}
                      <Icon name="thumb_up" filled={liked} /> 
                      {(liked?'You Liked':'Like it')} ({likeCount || 0})
                    </div>
                    <div className="post-item-comment">
                      {/* <img src="/comment.png" /> */}
                      <Icon name="chat_bubble" /> 
                         {post.commentCount || 0}
                    </div> 
                  </div>}

                  {comments ? 
                      <div className="post-item-comments">
                            <div   className="post-item-comment-more">.........</div>
                              {(post.commentsOfPost || []).map(c=> <div key={c.commentId} className="post-item-comment">
                                                        <CommentItem comment={c} />
                                                      </div>
                                                  )}  
                             <div   className="post-item-comment-more">.........</div>
                      </div>
                  :
                      <div className="post-item-comments">
                            {(post.commentsOfPost || []).map(c=> <div key={c.commentId} className="post-item-comment">
                                                        <CommentItem comment={c} />
                                                      </div>
                                                  )} 
                      </div>
                  }

               </div>  
  );

});

export default PostListItem;
