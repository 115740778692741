 
 import React, { Component, useEffect, useRef, useState }  from 'react';
 
  import './Follows.scss';   
import UserItem from '../Shared/UserItem';
import { api } from '../shorten/Shorten.Api';
import Loading from '../Shared/Loading';
import EmptyList from '../Shared/EmptyList';
 
function Follows({userId, type}) {
  
 
  const [users, setUsers] = useState([]); 
  const [loaded, setLoaded] = useState(false);
  
  useEffect(() => {
    loadData(); 
  }, []); 
  
  
  const loadData =  async () => {

      let follows = [];
      
      if(type=='follower'){
        follows = await api.getUsersFollowers(userId); 
      }
      if(type=='following'){
        follows = await api.getUsersFollowings(userId); 
      }
     
      follows = (follows || []).map(c=> {
        if(c){
          c.insertedDate = new Date(c.insertedDate) || new Date();
        }
        return c;
      });
       
      setLoaded(true);
      setUsers(follows);
      console.log('follows', follows);
  }


  return (
      <div className='follows'> 
          <div className='user-list'> 
             

            {!loaded && <Loading />}
            {users.length==0 && loaded && <EmptyList />}
            
           
            {users.map(u=>  <UserItem key={u.userName} user={u} onFollow={()=>{}} onUnFollow={()=>{}} /> )} 
          </div>
      </div>      
  
  );
}

export default Follows;
