 
 import React, { Component, useEffect, useRef, useState }  from 'react';
 
 import './Main.scss';   

import { useParams } from 'react-router-dom';
import { api } from '../shorten/Shorten.Api';
import Statics from '../Statics';
import Loading from '../Shared/Loading';
import Menu from '../Shared/Menu';
import Icon from '../Shared/Icon';
import PostHeadList from '../Home/PostHeadList';
 
import { register } from 'swiper/element/bundle';


import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

register();


function Main() {

  const routeParams = useParams();

  const [data, setData] = useState([]);  
  const [loaded, setLoaded] = useState(false); 
  const [activeIndex, setActiveIndex] = useState(0); 
  let [inited, setInited] = useState(false); 
  const swiperRef = useRef();

  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'}); 
    setLoaded(false);
    loadPosts(); 
  }, []); 
  
    

  const renderPosts =    (posts) => {
      return posts.map(r=>{
        r.insertedDate = new Date(r.insertedDate) || new Date();
        if(r.commentsOfPost && r.commentsOfPost.length>0){
          r.commentsOfPost = r.commentsOfPost.map(c=> {
            if(c){
              c.insertedDate = new Date(c.insertedDate) || new Date();
            }
            return c;
          })
        }
        return r;
      });
  }

  const loadPosts =  async () => {

   
    try{
      console.log('loading from local');
       let lastData = JSON.parse(localStorage['channel_posts_']);
       if(lastData && lastData.length>0){
        setData(renderPosts(lastData));
       }
       
    }
    catch(ex){

    }

    let result = await api.getChannelPosts(null);
    
     
  
      result = renderPosts(result);
  
      let jsonData =  JSON.stringify( result ); 
      if(localStorage['channel_posts_']==jsonData){

      }
      else{
        console.log('pushing to local');
        setData(result);
        localStorage['channel_posts_'] = jsonData;
      }

      setLoaded(true); 
     
  }
 

  const stopVideo = (index) =>{
    let videoObj = document.getElementById('video_'+index);
    if(videoObj){
      videoObj.pause();
      videoObj.currentTime = 0;
    }
  }


  const swiperLoaded = (ref) =>{

    if(inited){
      return;
    }
    setInited(true);
    inited=true;
    const swiperEl = document.querySelector('swiper-container');

    swiperEl.addEventListener('progress', (event) => {
      const [swiper, progress] = event.detail;
    });
  
    swiperEl.addEventListener('slidechange', (event) => {
      const [swiper, progress] = event.detail;
      console.log('slide changed', swiper);
      setActiveIndex(swiper.activeIndex);  
      stopVideo((swiper.activeIndex-2));
      stopVideo((swiper.activeIndex)); 
      console.log('vide', (swiper.activeIndex-1));

      if(document.getElementById('video_'+(swiper.activeIndex-1))){
        console.log('vide', document.getElementById('video_'+(swiper.activeIndex-1)));
        document.getElementById('video_'+(swiper.activeIndex-1)).src= document.getElementById('video_'+(swiper.activeIndex-1)).getAttribute('data-src');
        
      //  'https://pub-4658d3d574544ed7b3220d03d9ba8977.r2.dev/ojltpzg5en98jhfrh32d381wvcslgvdnfw4yb842_mobile.mp4';
        
        document.getElementById('video_'+(swiper.activeIndex-1)).play();
      }
    });
  }


  const slideChanged = (swiper) =>{  
    console.log('slideChanged', swiper, swiper.activeIndex);
    stopVideo((swiper.activeIndex-1));
    stopVideo((swiper.activeIndex+1));  
    let video = document.getElementById('video_'+(swiper.activeIndex));
    if(video){
      console.log('vide', video);
      video.src= video.getAttribute('data-src'); 
      video.play();
    }
  }
  

  let loggedIn = window.userData ? true: false;
 
  return (
    <div className="page channel">

       
        <div className='main-left'>
            <Menu />  
        </div>  
        <div className='main-title'>
           Channels
        </div>  
        <div className='main-center'>
            {/* {!loaded &&  <div   className="post-item-empty"> <Loading /> </div>} */}


            <Swiper
            ref={swiperRef} 
      spaceBetween={0}
      slidesPerView={1}
      onSlideChange={(swiper) => slideChanged(swiper)}
      onSwiper={(swiper) => console.log(swiper)}
       direction="vertical"
    >

<SwiperSlide style={{backgroundColor:'#321c49'}}>
      <img src="/swipe.gif" />
  </SwiperSlide>


       {data.map((d,ind)=>  <SwiperSlide  key={ind} > 
       <div className='swiper-title'>
        {d.title}
       </div>

       <div className='swiper-actions'>
       <div className='swiper-action'>
        <Icon name="favorite" /> 
       </div>
       <div className='swiper-action'> 
        <Icon name="comment" />
       </div>
         
       </div>
      <video  x5-playsinline="true" webkit-playsinline="true" mediatype="video" playsInline={true} data-src={d.content} src={''}  autoPlay="" id={'video_'+(ind+1)} controls={true} muted={false} loop={true}>

      </video>
  </SwiperSlide> )}  
    </Swiper>

            {/* {loaded &&  <swiper-container ref={swiperLoaded} slides-per-view="1" speed="100" loop="true" css-mode="true"
                                allow-touch-move="true" centered-slides="true" direction="vertical"
                               
                                >
 <swiper-slide style={{backgroundColor:'#321c49'}}>
      <img src="/swipe.gif" />
  </swiper-slide>

    {data.map((d,ind)=>  <swiper-slide  key={ind} > 
      <video  x5-playsinline="true" webkit-playsinline="true" mediatype="video" playsInline={true} data-src={d.content} src={''}  autoPlay="" id={'video_'+(ind+1)} controls={true} muted={false} loop={true}>

      </video>
  </swiper-slide> )} 
</swiper-container>
                       
              } */}
              
              <div className="post-placeholder"> </div>
        </div>
        <div className='main-right'>
            <PostHeadList  />
        </div>
 
 
    </div>
  );
}

export default Main;
