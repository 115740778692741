 
import './App.scss';
import AccountPopup from './components/AccountPopup';

import Footer from './components/Footer';
import Notifications from './components/Notification';
import NotificationsPage from './Generic/NotificationsPage';
// import HomeMain from './Home/_Main'; 
import PostMain from './Post/Main'; 
import Profile from './Profile/Profile'; 
import MyProfile from './Profile/MyProfile'; 
import UserDetail from './Profile/UserDetail';

import Channels from './Channel/Main';



import TrendingPosts from './Home/Trending';
import HomePosts from './Home/Home';
import NewPosts from './Home/New';
import BestPosts from './Home/Best';
import TodaysPosts from './Home/Today';
import Send from './Home/Send';


import UsePolicy from './pages/UsePolicy';
import Terms from './pages/Terms';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Pricing from './pages/Pricing';
import WhyUs from './pages/WhyUs';
import Resources from './pages/Resources';
import PageNotFound from './pages/PageNotFound';


import React, { Component, useEffect, useRef, useState }  from 'react';
import { api } from './shorten/Shorten.Api';
 
import { Routes, Route, Link ,useLocation, useNavigate} from "react-router-dom";
import Messenger from './Messages/Messenger';
import initSocket from './socket';
import { ChatStore } from './Messages/ChatStore';
import Menu from './Shared/Menu';
import MobileMenu from './Shared/MobileMenu';

import Helpers from './helpers';
import EditProfile from './Profile/EditProfile';

const MenuItem = (props)=>{
  const location = useLocation();
  var isActive = location.pathname === props.to;
  var className = isActive ? 'selected' : '';
  return (
    <Link   {...props} className={'menu-item ' + (isActive?' selected ':'')}>{props.children}</Link>
  );
}
window.currentPageUrl = '/';  

function App() {

  const notificationRef = useRef();
  const authRef = useRef();

  window.sns={};
  window.sns.notify =   (params) => { notificationRef.current.notify(params); }
  window.sns.login =  (params) => { authRef.current.showLogin(params); }
  window.sns.register =  (params) => { authRef.current.showRegister(params); }
  window.sns.forgotPassword =  (params) => { authRef.current.showForgotPassword(params); }
  window.sns.user = null;  
   
  if(!window.messages){
    console.log('seet message window', window.messages);
    window.messages = {};
  }
  
  
 
  // window.openPage = (id) =>{ console.log(id); }
  window.routingObj = useNavigate();  

  const [canLoad, setCanLoad] = useState(false);
  
  
  window.openPage = (url, save) => {
      if(save){
        localStorage['last_scroll'] = window.scrollY;
        localStorage['last_page'] = window.currentPageUrl ;
      }
      if(!save && localStorage['last_page']==url){
        if(localStorage['last_scroll']){
          try{   
              let scrollPos = parseInt(localStorage['last_scroll']);
              setTimeout(()=>{
                window.scrollTo({top: scrollPos, behavior: 'auto'});
              },100); 
              setTimeout(()=>{
                window.scrollTo({top: scrollPos, behavior: 'auto'});
              },200);
              setTimeout(()=>{
                window.scrollTo({top: scrollPos, behavior: 'auto'});
              },400); 
              setTimeout(()=>{
                window.scrollTo({top: scrollPos, behavior: 'auto'});
              },600); 
              setTimeout(()=>{
                window.scrollTo({top: scrollPos, behavior: 'auto'});
              },750); 
          }
          catch(ex){
            window.scrollTo({top: 0, behavior: 'auto'});
          }
        } 
      }
      window.currentPageUrl  = url;
      window.routingObj(url);
   } 

  window.mainPageOpening = (url) => {
    console.log('mainPageOpening', url);
    if(localStorage['last_page']==url){
      if(localStorage['last_scroll']){
        try{   
            let scrollPos = parseInt(localStorage['last_scroll']);
            setTimeout(()=>{
              window.scrollTo({top: scrollPos, behavior: 'auto'});
            },100); 
            setTimeout(()=>{
              window.scrollTo({top: scrollPos, behavior: 'auto'});
            },200);
            setTimeout(()=>{
              window.scrollTo({top: scrollPos, behavior: 'auto'});
            },400); 
            setTimeout(()=>{
              window.scrollTo({top: scrollPos, behavior: 'auto'});
            },600); 
            setTimeout(()=>{
              window.scrollTo({top: scrollPos, behavior: 'auto'});
            },750); 
        }
        catch(ex){
          window.scrollTo({top: 0, behavior: 'auto'});
        }
      } 
    }
    localStorage['last_page'] = url;
    window.currentPageUrl  = url; 
 } 
 
  let hasUser =  window.userData?true:false;


  const onLoad = async () => { 
    
    if(localStorage["token"]){
      
      window.token = localStorage["token"];
      let checkResult = await api.checkLogin();
      console.log('checkLogin ', checkResult);
      if(!checkResult){
        await api.getToken(); 
      }
    }
    else{
      await api.getToken(); 
      
    }  
    if(localStorage["user"])
    {
      window.userData = JSON.parse( localStorage["user"]);
    }
    if(!window.token){ 
      if(!localStorage["reloaded"]){
        localStorage["reloaded"] = 'true';
        window.location.reload();
      }
      
    }
    setCanLoad(true); 
    initSocket();
  }

  useEffect(() => {  
    onLoad();  
  }, []); 


  let sentMessages = [];
  ChatStore.onSendMessage.follow((data)=>{
      console.log('SEENDDDDDDDDD>>>>>>>>>');
      if(sentMessages.indexOf(data.id)<=-1){
        sentMessages.push(data.id);
        window.messages.send(data);
      }

     
  });

 

    window.messages.receiver=(sender, message)=>{ 
        
      console.log('window.messages.receiver',sender, message);
        let notified = ChatStore.onNewMessage.fire({sender, message});
        if(!notified){
          window.sns.notify({ title: sender.userName, text: message, image:sender.avatarUrl, time: 4 });
        }

        // let content = { 
        //   senderId: sender.userId,
        //   userName:sender.userName,
        //   avatarUrl:sender.avatarUrl,
        //   messageId:new Date().getTime(),
        //   insertedDate:new Date(),
        //   receiverId: window.userData.userId,
        //   content: message,
        //   contentType: Statics.CONTENT_TYPE.TEXT
        // };
        // messagesRef.current.send(content);
        // console.log('messagesRef.current.send',selectedSession, content);
        // if(selectedSession && selectedSession.userId==sender.userId){
        //   console.log('do not notify');
        //   return true;
        // }
        
    };


  if(!canLoad){
    return <></>
  }

  return (
    <div className="app">

      <div className="app-header-wrapper">
      {/* <div className="app-header"> */}

        {/* <div className='search'> */}
          {/* <input type="text" placeholder='SEARCH' /> */}
        {/* </div> */}
        {/* <div className='language'>
           <select >
            <option value="turkish">English</option>
            <option value="turkish">Chinese</option>
            <option value="turkish">Spanish</option>
            <option value="turkish">Turkish</option>
           </select>
        </div> */}
        {/* <div className='menu'> */}
{/*           
            <MenuItem to={'/'}>Home</MenuItem>
            <MenuItem to={'/whyus'} className={'menu-item '} >Why Us?</MenuItem>
            <MenuItem to={'/pricing'} className='menu-item'>Pricing</MenuItem>
            <MenuItem to={'/resources'} className='menu-item'>Resources</MenuItem>  */}
        {/* </div> */}

        {!hasUser && <div className='account'> 
            <div className='login-button' onClick={()=> window.sns.login() }>Login</div> or 
            <div className='register-button' onClick={()=> window.sns.register() }>Register</div>
            to see more.
        </div>}
        
        {/* {hasUser && <div className='account'> 
          <div className='menu-item' onClick={() => {localStorage['user'] = null; window.location.reload();}}>Logout</div> 
        </div>} */}
        

      {/* </div> */}


      </div>

        <Routes>

        <Route path="/trending" element={<TrendingPosts />} />
        <Route path="/home" element={<HomePosts />} />
        <Route path="/new" element={<NewPosts />} />
        <Route path="/best" element={<BestPosts />} />
        <Route path="/today" element={<TodaysPosts />} />
        <Route path="/send" element={<Send />} />
        <Route path="/send/:type" element={<Send />} />
        

            <Route path="/" element={<TrendingPosts />} />
            <Route path="/post/:id" element={<PostMain />} />

            <Route path="/myprofile" element={<MyProfile />} />
            <Route path="/mypost" element={<UserDetail type="post" />} />
            <Route path="/mycomment" element={<UserDetail type="comment"  />} />
            <Route path="/myfollower" element={<UserDetail type="follower"  />} />
            <Route path="/myfollowing" element={<UserDetail type="following"  />} />
            

            <Route path="/myprofile/:tab" element={<MyProfile />} /> 

            <Route path="/profile" element={<Profile />} />
            <Route path="/profile/:id" element={<Profile />} />
            <Route path="/profile/:id/:tab" element={<Profile />} />
            <Route path="/profile/edit" element={<EditProfile />} />

            <Route path="/messages" element={<Messenger />} />
            <Route path="/channels" element={<Channels />} />
            
    
            

            <Route path="/whyus" element={<WhyUs />} />
            <Route path="/Resources" element={<Resources />} /> 
      

            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/usepolicy" element={<UsePolicy />} />
            <Route path="*" element={<PageNotFound />} />
            <Route path="*" element={<PageNotFound />} />
            <Route path="/notifications" element={<NotificationsPage />} />
            
 

            

            {/* <Route path="/order/:id" element={<OrderDetail />} /> */}
 
       </Routes>
        
        {Helpers.isMobile() && <MobileMenu />}
        {/* <WhyUs /> */}
        {/* <Pricing /> */}
        {/* <BackendMain /> */}
        {/* <ShortenMain />   */}
        <AccountPopup ref={authRef} />
        <Notifications ref={notificationRef} />

        {/* <Footer/> */}
        
    </div>
  );

}

export default App;
