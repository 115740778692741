 
 import React, { Component, useEffect, useRef, useState }  from 'react';
 import './Main.scss';  
import PostListingMainPage from './PostListingMainPage';
 
function New() { 
    return <PostListingMainPage type={'new'} />
}

export default New;
