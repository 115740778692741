
import helpers from '../helpers';
import './Loading.scss';
import Logo from './Logo';


export default function Loading() {


  if(helpers.isMobile()){
    return <div className="loader"><Logo /></div>
  }

  return (<div className="loader">
            <div className="loader-inner">
              <div className="loader-line-wrap">
                <div className="loader-line"></div>
              </div>
              <div className="loader-line-wrap">
                <div className="loader-line"></div>
              </div>
              <div className="loader-line-wrap">
                <div className="loader-line"></div>
              </div>
              <div className="loader-line-wrap">
                <div className="loader-line"></div>
              </div>
              <div className="loader-line-wrap">
                <div className="loader-line"></div>
              </div>
            </div>
          </div>
        );
}