
import { Link, useLocation, useParams } from 'react-router-dom';
import './Menu.scss';
import Icon from './Icon';
import Logo from './Logo';



const MenuItem = (props) => {
  const location = useLocation();
  var isActive = location.pathname.split('/')[1] === props.to.split('/')[1];
  if (isActive && props.dontSelect) {
    isActive = false;
  }
  let disabled =  props.disabled;



  var className = isActive ? 'selected' : '';
  let newProps = { ...props };
  delete newProps.dontSelect;
  delete newProps.disabled;

  if(disabled){
    newProps.to='';
    isActive = false;
  }

  return (
    <Link   {...newProps} className={'left-menu-item ' + (isActive ? ' selected ' : '') + (disabled ? ' disabled ':'')} onClick={() => {
      window.mainPageOpening(props.to);
    }}>
      {props.children}
    </Link>
  );
}


const onLogoutClicked = () =>{
    if(window.confirm('Are you suure to logout?')){
      localStorage['user'] = null;
      localStorage['userdata'] = null;
      localStorage['token'] = null;
      window.location.href='/trending';
    }
}

function Menu() {

  let params = useParams();
  console.log(params);
  let isMe = (!params.id || (window.userData || {}).userId == params.id);

  let me =  window.userData;
 

  return (
    <>
    <div className='left-menu'>
        <Logo />
      {/* <img src="/resdf.png" className='logo' onClick={() => { window.openPage('/') }} /> */}


{/* 
      <div className='logobg'>
        <img src="/mask.png" className='logomask' />
        <img src="/resdf.png" className='logomask resdf' />
        <img src="/rainbow.png" />
      </div> */}
      <MenuItem to='/home' disabled={!me}>
        <Icon name="home" />
        Home
      </MenuItem>
      {/* <MenuItem to='/today'>
        <Icon name="today" />
        Today
      </MenuItem> */}
      <MenuItem to='/trending'>
        {/* <img src="/trending.png" /> */}
        <Icon name="tag" />
        Trending
      </MenuItem>
      <MenuItem to='/new'>
        {/* <img src="/24-hours.png" /> */}
        <Icon name="sentiment_excited" />
        New
      </MenuItem>
      {/* <MenuItem to='/channels' disabled={!me}> 
        <Icon name="groups_3" />
        Channels
      </MenuItem> */}
      <MenuItem to='/messages'  disabled={!me}>
        {/* <img src="/chat.png" /> */}
        <Icon name="forum" />
        Messages
      </MenuItem>
      <MenuItem to='/myprofile' dontSelect={!isMe}  disabled={!me}>
        {/* <img src="/driving-license.png" /> */}
        <Icon name="person" />
        My Profile
      </MenuItem>

      {/* <MenuItem to='/mywallet'   disabled={!me}> 
        <Icon name="wallet" />
        My Wallet
      </MenuItem> */}
      {/* <MenuItem to='/settings'>
                <img src="/settings.png" />
                Settings
            </MenuItem> */}
      {/* <MenuItem to='/settings'>
         <Icon name="monitoring" />
        Analytics
      </MenuItem> */}
      <MenuItem to='/notifications'  disabled={!me}>
        {/* <img src="/identity-card.png" /> */}
        <Icon name="notifications" />
        Notifications
      </MenuItem>
     
    </div>
      {me && 
          <div className='account-popover' >
            
              <div className='account-popover-item' ><MenuItem to='/analytics'>Analytics</MenuItem></div>
              <div className='account-popover-item' ><MenuItem to='/profile/edit'>Edit profile</MenuItem></div>
              <div className='account-popover-item' onClick={onLogoutClicked} >Logout</div> 
            
              <div className='account-menu' >
              
                <div className='avatar' style={{backgroundImage:'url('+(me.avatarUrl || '/guest.png')+')'}} ></div>
                <div className='name' >@{me.userName}</div>
                <div className='desc' >Level:{me.level}</div>
                <Icon className="more" filled={true} name="more_horiz" />
                
              </div>
          </div>
      }
      </>
  );
}

export default Menu;
