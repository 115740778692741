

import React from 'react'; 
 

const countries = [
  { name: 'Choose your countrry', value: 'none' },
  { name: 'United States Of America', value: 'usa' },
  { name: 'United Kingdom', value: 'uk' },
  { name: 'Germany', value: 'de' },
  { name: 'France', value: 'fr' },
  { name: 'China', value: 'ch' },
  { name: 'India', value: 'in' },
  { name: 'Turkey', value: 'tr' },
  { name: 'Canada', value: 'ca' },
  { name: 'Russia', value: 'ru' },
  { name: 'Japan', value: 'jp' },
  { name: 'Brazil', value: 'br' },
  { name: 'United Arab Emirates', value: 'uae' },
  { name: 'Singapore', value: 'sg' },

]
const CountrySelect =({value, onChange}) => {
 

  return (
            <select type="text" name="country" onChange={onChange} value={value} placeholder='Your country'>
              {countries.map(c => <option key={c.value}  value={c.value}>{c.name}</option>)}
            </select> 
  );
  
}


export default CountrySelect;

