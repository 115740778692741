 
 import React, { Component, useEffect, useRef, useState }  from 'react';
 
 import './UserItem.scss';   
import { api } from '../shorten/Shorten.Api';
 
function UserItem({user, onUnFollow, onFollow}) {
 
  const [following, setFoollowing] = useState(user.uFollowing); 

  const unfollowUser = () =>{
    setFoollowing(false);
    onUnFollow();
    api.unfollow(user.userId);
  }

  const followUser = () => {
    setFoollowing(true);
    onFollow();
    api.follow(user.userId);
  }


  const gotoUserProfile=(userId)=>{ 
    window.openPage('/profile/'+userId); 
  }

  const getFollowButton=(following)=>{ 
    let loggedIn = window.userData ? true: false;
    if(!loggedIn){
      return <></>
    }
    if(following){
      return <div className='unfollow' onClick={unfollowUser}>Unfollow</div>
    }
    else{ 
      return  <div className='follow'  onClick={followUser}>Follow</div>
    }  
  }

  let isSameUser = window.userData && window.userData.userId == user.userId;
 

  // if(isSameUser){
  //   return <></>
  // }

  return ( <div className='user-item' key={user.userName}>
              <div className='avatar'><img src={user.avatarUrl || '/guest.png'}  onClick={()=>gotoUserProfile(user.userId)}/></div>
              <div className='name'  onClick={()=>gotoUserProfile(user.userId)}>@{user.userName}</div>
              <div className='level'  onClick={()=>gotoUserProfile(user.userId)}>Level: {user.level}</div>
              
              {!isSameUser && getFollowButton(following)}
            </div>  
  );
}

export default UserItem;
