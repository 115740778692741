 import Statics from '../Statics';
import helpers from '../helpers';
import { api } from '../shorten/Shorten.Api';
import CommentContent from './CommentContent';
import './CommentItem.scss'; 
 import React, { Component, useImperativeHandle, forwardRef,useState }  from 'react';
import Icon from './Icon';

   

const CommentItem =  ({comment}) => {
 
  const sendCommentReply = () =>{

  }
  return ( 
               <div key={comment.commentId} className="comment-item">   

                  <div className="comment-item-sender">
                      <img src={comment.avatarUrl  || '/guest.png'} />
                      <div className='name-date'>
                      <span className='name'>{comment.userName || 'Guest User'}</span>   
                      <span className='time'>{helpers.dateToString( comment.insertedDate, 'YYYY-MM-DD hh:mm')}</span>
                      </div>
                  </div>
                  {comment.description && <div className="comment-item-description">{comment.description}</div>}
                  {comment.content && comment.contentType && 
                  <CommentContent comment={comment} canClick={false} />

                  // <div className="comment-item-content">
                  //   {comment.contentType==Statics.CONTENT_TYPE.IMAGE && <img src={comment.content} />}
                  //   {comment.contentType==Statics.CONTENT_TYPE.VIDEO && <video src={comment.content} />} 
                  //   {comment.contentType==Statics.CONTENT_TYPE.YOUTUBE_VIDEO &&  } 
                  //   {comment.contentType==Statics.CONTENT_TYPE.EMOJI && <img className='emoji-comment' src={comment.content} />} 
                  //   {comment.contentType==Statics.CONTENT_TYPE.RANDOM && comment.content}
                  // </div> 
                  } 
                  <div className='comment-buttons'>    
                      <div className='comment-button' onClick={() => sendCommentReply('/emoji_angry.png', Statics.CONTENT_TYPE.EMOJI_ANGRY)}>
                        <Icon name="thumb_up"  />
                        222
                      </div> 
                      <div className='comment-button' onClick={() => sendCommentReply('/emoji_angry.png', Statics.CONTENT_TYPE.EMOJI_ANGRY)}>
                        <Icon name="thumb_down"  />
                        122
                      </div> 
                    
                  </div>
               </div>  
  );
};

export default CommentItem;


