
import './EmptyList.scss';


export default function EmptyList() {


  return ( <div className='empty-list'> 
  <div className='empty-list-text'> Nothing here yet...</div>
    <div className='image'><img width="100%" src="/desert.jpg" /> </div>
  </div>
        );
}