 
 import React, { Component, useEffect, useRef, useState }  from 'react';
import UserProfile from './UserProfile';
 
 
function Profile() {

   

  return (
     <UserProfile />
  )
   
}

export default Profile;
