import React, { Component, useImperativeHandle, forwardRef,useState, useEffect }  from 'react';
import Statics from '../Statics';
import Icon from './Icon';

   

const CommentContent = ({comment, canClick}) => {
  
 
 
  const gotoComment = () =>{
    window.openPage('/post/'+comment.postId, true);
  } 
 
  // const getContent=(content)=>{
  //     var str =  (content || '')
  //     .replace(/((http:|https:)[^\s]+[\w])/g,'<a href="$1" target="_blank">$1</a>')
  //     .replace(/\r\n|\r|\n/g,"<br />");

  //     return <div dangerouslySetInnerHTML={{__html: str }} ></div>
  // }

  const gotoCommentClicked = () =>{
    if(canClick){
      gotoComment();
    }
  }

  return (      
  
 
          <div className={"comment-item-content"} >
            
            {comment.contentType===Statics.CONTENT_TYPE.RANDOM && 'resdfasda'}
            {comment.contentType===Statics.CONTENT_TYPE.EMOJI_FP && <img className='emoji' src="/emoji_fp.png" />}
            {comment.contentType===Statics.CONTENT_TYPE.EMOJI_LOVE &&  <img className='emoji' src="/emoji_loved.png" />}
            {comment.contentType===Statics.CONTENT_TYPE.EMOJI_LOL &&  <img className='emoji' src="/emoji_lol.png" />}
            {comment.contentType===Statics.CONTENT_TYPE.EMOJI_CRY && <img className='emoji' src="/emoji_cry.png" />}
            {comment.contentType===Statics.CONTENT_TYPE.EMOJI_BLOW &&  <img className='emoji' src="/emoji_blow.png" />}
            {comment.contentType===Statics.CONTENT_TYPE.EMOJI_ANGRY &&  <img className='emoji' src="/emoji_angry.png" />}
 
                    {comment.contentType==Statics.CONTENT_TYPE.TEXT && comment.content}
                    {comment.contentType==Statics.CONTENT_TYPE.IMAGE && <img src={comment.content} />}
                    {comment.contentType==Statics.CONTENT_TYPE.VIDEO && <video src={comment.content} />}
                    {comment.contentType==Statics.CONTENT_TYPE.YOUTUBE_VIDEO && 
                                                            <div className="youtube-container" onClick={()=>gotoCommentClicked()}>
                                                                <img src={'https://i.ytimg.com/vi/'+comment.content.replace('https://www.youtube.com/watch?v=','').replace('https://www.youtube.com/embed/','')+'/hqdefault.jpg'} />
                                                                <Icon name="sound_sampler" className="play-button" />
                                                                {/* <iframe   src={post.content} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe> */}
                                                            </div>} 
          </div>  

  );

};

export default CommentContent;
