import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App'; 

// import reportWebVitals from './reportWebVitals';

import { BrowserRouter } from "react-router-dom";

// window.VARS = {
//   apiUrl: 'http://46.197.172.3:3030/url',
//   shortUrl: 'http://46.197.172.3:3030/',
//   siteUrl: 'http://localhost:8080/',
// };
window.VARS = {

  // apiUrl: 'https://localhost:7137/',
  apiUrl: 'https://api.resdf.com/',
  fileApiUrl: 'https://resdffilemanager.losmedia.com/', 
  // fileUrl:'https://pub-4658d3d574544ed7b3220d03d9ba8977.r2.dev/',
  fileUrl:'https://r2.losmedia.com/', 
  shortUrl: 'https://u4.ink/',
  siteUrl: 'https://1shorturl.com/',
};


// window.addEventListener('scroll',(e)=>{
//   console.log(window.scrollY);
// })



 

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
     <BrowserRouter>
    <App />
    </BrowserRouter>
  </React.StrictMode>
);
 
// reportWebVitals();




