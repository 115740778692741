//  import CommentItem from '../Post/CommentItem';
import PostList from '../Shared/PostList';
import { api } from '../shorten/Shorten.Api';
// import './PostList.scss'; 
 import React, { Component, useImperativeHandle, forwardRef,useState, useEffect }  from 'react';

   

const MainPostList =  forwardRef(({type}, ref) => {

  useImperativeHandle(ref, () => ({
      addPost:addPost
  }));


  const addPost = (item) =>{ 
    setPosts([item, ...posts]);
  }


  const gotoPost = (item) =>{
    window.openPage('/post/'+item.postId);
  }


  const [posts, setPosts] = useState([]);


  useEffect(() => {
    loadPosts(); 
  }, [type]); 


  const renderPosts =    (posts) => {
      return posts.map(r=>{
        r.insertedDate = new Date(r.insertedDate) || new Date();
        if(r.commentsOfPost && r.commentsOfPost.length>0){
          r.commentsOfPost = r.commentsOfPost.map(c=> {
            if(c){
              c.insertedDate = new Date(c.insertedDate) || new Date();
            }
            return c;
          })
        }
        return r;
      });
  }

  const loadPosts =  async () => {

   
    try{
      console.log('loading from local');
       let lastData = JSON.parse(localStorage['post_list_'+type]);
       if(lastData && lastData.length>0){
        setPosts(renderPosts(lastData));
       }
       
    }
    catch(ex){

    }

    let result = [];
    if(type=='trending'){
      result = await api.getTrendPosts(null);
    } 
    if (type == 'home') {
      result = await api.getHomePosts(null);
    } 
    if(type=='new'){
      result = await api.getRecentPosts(null);
    }
    if(type=='hot'){
      result = await api.getBestPosts(null);
    }
    if(type=='me'){
      result = await api.getUsersPosts(window.userData.userId);
    }

     
  
      result = renderPosts(result);
  
      let jsonData =  JSON.stringify( result ); 
      if(localStorage['post_list_'+type]==jsonData){

      }
      else{
        console.log('pushing to local');
        setPosts(result);
        localStorage['post_list_'+type] = jsonData;
      }

     
  }
 

  return (
      <PostList posts={posts} />
  );

});

export default MainPostList;
