 
 import React, { Component, useEffect, useRef, useState }  from 'react';
 import './Main.scss';  
import PostListingMainPage from './PostListingMainPage';
 
function Today() { 
    return <PostListingMainPage type={'today'} />
}

export default Today;
