 
 import React, { Component, forwardRef, useEffect, useImperativeHandle, useRef, useState }  from 'react';
 
 import './SessionList.scss';    
import { api } from '../shorten/Shorten.Api';
import Loading from '../Shared/Loading';
import MessageSessionItem from './MessageSessionItem';
import Icon from '../Shared/Icon';
 
const SessionList= forwardRef(  ({userId, onSessionSelected, onStartSearch, selectedItem}, ref) => {
 
 
  
  useImperativeHandle(ref, () => {
      return {
        notify: notify
      }
  }); 

 
    
  
    const [users, setUsers] = useState([]);
    const [data, setData] = useState([]);
    const [loaded, setLoaded] = useState(false);


    useEffect(() => {
      loadPosts(); 
    }, [userId]); 


    const notify =  async (senderId) => {

        let user = users.filter(u=>u.userId==senderId)[0] || {};
        user.notify= user.notify || 0;
        user.notify++;
        setUsers([...users]);
    }


    const loadPosts =  async () => {

      let result   = await api.getUsersMessageSessions(userId) || [];
      
      
  
      result = result.map(r=>{
        r.insertedDate = new Date(r.insertedDate) || new Date();
        
        return r;
      });

      if(selectedItem && selectedItem.userId){
        if(!result.find(r=>r.userId==selectedItem.userId)){
            result = [selectedItem, ...result];
        }
        setSelectedSession(selectedItem);
      }

        setLoaded(true);
        setUsers(result);
        setData(result);
    }

    
    const searchUser =  async (e) => {
        let keyword   = e.target.value.toLowerCase().trim();
        setUsers(
          [...data.filter(u=> { u.order = u.userName.toLowerCase().indexOf(keyword); return u.order>-1; }).sort((a,b)=> a.order>b.order?1:-1)]
        );
    }

  const [selectedSession, setSelectedSession ] = useState(null);
  const openChat = (user) =>{ 
    setSelectedSession(user);
    onSessionSelected(user);
  } 

  return (
      <div className='session-profiles'> 
          <div className='user-list'>
            <div className='user-list-title'>
                Mesajlar 
                <div className='start-search' onClick={onStartSearch} >
                  <Icon name="person" />  
                </div>


            </div>
            {!loaded && <Loading />} 
            {loaded && <div className='search-user'>
              <input type="text" placeholder='Search user' onChange={(e)=>searchUser(e)} />
            </div>}
            <div className='user-list-scroller'>
              {users.map(u=> <MessageSessionItem key={u.userName}  user={u} selectedSession={selectedSession || {}} onOpenChat={openChat}  /> )} 
            </div>
          </div>
      </div>      
  
  );
});

export default SessionList;
