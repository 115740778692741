 
import './Tab.scss';

 

import React, { Component, useEffect, useRef, useState } from 'react';
 
     

export  function Tabs(props) { 
  
  const [selected, setSelected] = useState(0);

  const tabs = React.Children.toArray(props.children).map( (child, i) => { 
    return child.props;
  });


  useEffect(() => {  
    console.log(window.location.pathname);
    let selectedTabInd = 0;
    tabs.forEach((t,ind)=>{
      if(t.url==window.location.pathname)
        selectedTabInd = ind;
    });
     if(selectedTabInd!=selected){
        setSelected(selectedTabInd);
     }
   }, []);
 
  




  const gotoTab=(ind, url)=>{
    setSelected(ind);
    if(props.navigate){ 
      window.openPage(url);
    }
  }

  // const updateChildrenWithProps = React.Children.map(
  //   props.children,
  //   (child, i) => {
  //     return React.cloneElement(child, {...child.props } );
  //   }
  // );

  // const currentTabContent=()=>{
  //   if(tabs[selected].url && tabs[selected].url==window.location.pathname){
  //      return tabs[selected].children;
  //   }
  //   return null;
  // }

  return  <>
      <div   className='tab-header-wrapper'>
      <div   className='tab-header'>
          {tabs.map((t,ind)=>
              <div key={ind} onClick={()=>gotoTab(ind, t.url)} className={'tab-title '+(selected==ind?' selected ':'')} >
                {t.title}
              </div> 
          )}  
      </div> 
      </div> 
      <div   className={'tab-content ' + tabs[selected].className} >
          {tabs[selected].children}
          {!tabs[selected].url?tabs[selected].children:<></>}
      </div> 
   
  </>
}

export  function Tab(props) {  
  return  <></>
}
