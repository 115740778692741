
import React, { Component, useEffect, useRef, useState } from 'react';

import './Main.scss';
import PostList from './MainPostList';
import PostHeadList from './PostHeadList';
import StartPost from './StartPost';
import Menu from '../Shared/Menu';
import { api } from '../shorten/Shorten.Api';
import MainPostList from './MainPostList';
import Logo from '../Shared/Logo';
import Icon from '../Shared/Icon';
import Helpers from '../helpers';



function PostListingMainPage({ type }) {

    const postListRef = useRef();




    const addPosts = (post) => {
        postListRef.current.addPost(post);
    }


    const postPageChanged = (e)=>{
        let type = e.target.value;
        if(type=='home'){
            window.openPage('/home');
        }
        if(type=='trending'){
            window.openPage('/trending');
        }
        if(type=='new'){
            window.openPage('/new');
        }
    }


    var activePage = window.location.pathname.split('/')[1];
    
    let isMobile = Helpers.isMobile();

    return (
        <div className="page home">

            <div className='main-title'>
                {/* <Logo /> */}
                {/* <select className='main-title-switch' onChange={postPageChanged}>
                    <option value="home" selected={window.location.pathname.indexOf('/home')>-1}>HOME</option>
                    <option value="trending" selected={window.location.pathname.indexOf('/trending')>-1}>TRENDING POSTS</option>
                    <option value="new" selected={window.location.pathname.indexOf('/new')>-1}>NEW POSTS</option>
                </select> */}
                <div className='main-title-text'>
                {type == 'today' && 'TODAY\'S TOPICS'}
                {type == 'trending' && 'TRENDING POSTS'}
                {type == 'home' && 'HOME'}
                {type == 'best' && 'BEST OF ALL THE TIME'}
                {type == 'new' && 'WHAT IS NEW? (24H)'}
                </div>

                <div className='main-title-text-mobile'> 

                    <div className='main-tabs'>
                        <div className={'main-tab '+  (activePage=='home'?' selected ':'')} onClick={()=>{ window.openPage('/home') }}>Home</div>
                        <div className={'main-tab '+  (activePage=='trending'?' selected ':'')} onClick={()=>{ window.openPage('/trending') }}>Trending</div>
                        <div className={'main-tab '+  (activePage=='new'?' selected ':'')} onClick={()=>{ window.openPage('/new') }}>New</div>
                    </div>
                    
                </div>
               
                <div className='main-title-button'>
                    <Icon name="workspaces" filled={true} className="home-more" />
                </div>

            </div>
            <div className='main-left'>
                <Menu />
            </div>
            <div className='main-center'>


                {!isMobile && <StartPost placeholder="Start Posting" enableFlags={true} onAdd={addPosts} />}
                <MainPostList type={type} ref={postListRef} />
            </div>
            <div className='main-right'>
                <PostHeadList /> 
            </div>

        </div>
    );
}

export default PostListingMainPage;
