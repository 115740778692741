
import { Link, useLocation, useParams } from 'react-router-dom';
import './Logo.scss'; 
 

function Logo() {
 

  return ( <div className='logo'>
                <div className='logobg'>
                  <img src="/mask.png" className='logomask' />
                  <img src="/resdf.png" className='logomask resdf' />
                  <img src="/rainbow.png" />
                </div> 
            </div> 
  );
}

export default Logo;
