 
 import React, { Component, useEffect, useRef, useState }  from 'react';
 import './Main.scss';  
import PostListingMainPage from './PostListingMainPage';
 
function Home() { 
    return <PostListingMainPage type={'home'} />
}

export default Home;
