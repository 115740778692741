 
 import React, { Component, useEffect, useRef, useState }  from 'react';
 
 import './UserProfile.scss';  
import PostHeadList from '../Home/PostHeadList';
import UserCommentList from './UserCommentList';
import StartPost from '../Home/StartPost';
import { useParams } from 'react-router-dom';
import { api } from '../shorten/Shorten.Api';
import PostList from '../Home/MainPostList';
import Menu from '../Shared/Menu';
import MainPostList from '../Home/MainPostList';
import UserPostList from './UserPostList';
import { Tab, Tabs } from '../Shared/Tab';
import SuggestedProfiles from './SuggestedProfiles';
import Follows from './Follows';

 window.some_func = function(){
    alert('a');
 }
function UserProfile() {

  const routeParams = useParams();
  
  const [user, setUser] = useState(null);  
  const [isMe, setIsMe] = useState(false); 
 
  const [following, setFoollowing] = useState(false); 

 const postListRef = useRef();



 const addPosts = (post) =>{
  postListRef.current.addPost(post);
}

  useEffect(() => {
    loadPosts(); 
  }, []); 
  
  
  
  const setUserData = (userToShow) => {

    userToShow.level = 'Member';
    
    switch(userToShow.roleId){
      case 1: userToShow.level = 'Member';
      case 2: userToShow.level = 'Pro';
      case 3: userToShow.level = 'Expert';
      case 4: userToShow.level = 'Partner';
    }
 
    setFoollowing(userToShow.uFollowing);
    setUser(userToShow);
  }
       
  const loadPosts =  async () => {
       
      let userToShow = {};
      if(routeParams.id && routeParams.id!=(window.userData || {}).userId){
        userToShow = await api.getUser(routeParams.id);
        setUserData(userToShow);
      }
      else{ 
        setUserData((window.userData || {}));
        setIsMe(true);
      } 
  }

  const unfollowUser = () =>{
    setFoollowing(false); 
    user.following = false;
    setUser(user);
    api.unfollow(user.userId);
  }

  const followUser = () => {
    setFoollowing(true); 
    user.following = true;
    setUser(user);
    api.follow(user.userId);
  }

   
  let getTabUrl = (subPage) => (isMe ? '/myprofile/'+subPage:'/profile/'+user.userId+'/'+subPage);
  let loggedIn = window.userData ? true: false;
   
  return (
    <div className="page profile">
          
        <div className='main-title'>
            {isMe?'My Profile':'Profile'}
        </div>  

        
        <div className='main-left'>
            <Menu />  
        </div>  
        <div className='main-center'>
            {user && <>
              <div className='profile-top'> 
                  <div className='avatar'><img src={user.avatarUrl || '/guest.png'} /></div>
                  <div className='name'>@{user.userName}</div>
                  <div className='stats'> 
                    <div className='stats-line'>RESDF Score: {user.score}</div>
                    <div className='stats-line'>Level: {user.level}</div> 
                    {!isMe && loggedIn && <>
                      {following?
                          <div className='unfollow' onClick={unfollowUser}>Unfollow</div>
                          :
                          <div className='follow'  onClick={followUser}>Follow</div>
                          }
                    </>}
                  </div>
              </div>   
              <Tabs navigate={true}>
                <Tab title="Posts" url={getTabUrl('posts')}>
                  {isMe && 
                      <StartPost placeholder="Start Posting" enableFlags={true} onAdd={addPosts} />  
                  }
                      <UserPostList userId={user.userId} ref={postListRef} />
                </Tab>
                <Tab title="Comments"  url={getTabUrl('comments')}> 
                      <UserCommentList userId={user.userId}  />
                </Tab>
                <Tab title="Followers"  url={getTabUrl('followers')}>
                    <Follows  type="follower" userId={user.userId}  />
                </Tab>
                <Tab title="Following"  url={getTabUrl('followings')}>
                    <><Follows  type="following" userId={user.userId}  /></>
                </Tab>
              </Tabs>
            </> }
            
        </div>
        <div className='main-right'>
          {user && <SuggestedProfiles userId={user.userId} />}
        </div>

    </div>
  )
   
}

export default UserProfile;
