import './PostList.scss'; 
 import React, { Component, useImperativeHandle, forwardRef,useState, useEffect }  from 'react';
import PostListItem from './PostListItem';

   

const PostList =  forwardRef(({posts}, ref) => {
  

  return ( 
    <div className="post-list">
        {posts.map(i=> 
              <PostListItem key={i.postId} post={i} />
        )}
    </div>
  );

});

export default PostList;
