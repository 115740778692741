export const api = {

     // CONTENT_TYPE: {
     //      TEXT:1, 
     //      IMAGE:2, 
     //      VIDEO:3,
     //      YOUTUBE_VIDEO:4,
     //      EMOJI:5,
     //      RANDOM:6
     // },

     getHtml: async (url)=>{

          let response = await fetch(url, {
               method: 'GET',    
               headers: { 'Access-Control-Allow-Origin': '*'  }
          });
          if(response.status===200){
               let data = await response.text();
               if(data===null){
                   return null;
               }
               return data;
          }
          else{
               return null;
          }
       },
    
       getRaw: async (url)=>{
          let headers = {
               'Content-Type': 'application/json',
               'Access-Control-Allow-Origin': '*'
          }
          if( window.token || localStorage['token']){
               headers['Authorization'] = 'Bearer ' +  (window.token || localStorage['token']);
          }
     
     
            let response = await fetch(url,{
               method: 'GET',    
               headers: headers
            });
            if(response.status===200){
                 let data = await response.text();
                 if(data===null){
                     return null;
                 }
                 return data;
            }
            else{
                 return null;
            }
         },
    get: async (url)=>{
     let headers = {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
     }
     if( window.token || localStorage['token']){
          headers['Authorization'] = 'Bearer ' + (window.token || localStorage['token']);
     }

     // console.log('geetting ', url, window.token, headers);

     // console.log('headers' , headers);

       let response = await fetch(url,{
          method: 'GET',    
          headers: headers
       });
       if(response.status===200){
            let data = await response.json();
            if(data===null){
                return null;
            }
            return data;
       }
       else{
            return null;
       }
    },
 
    post: async (url, data)=>{
     let headers = {
          'Content-Type': 'application/json'
     }
     if( window.token){
          headers['Authorization'] = 'Bearer ' +  window.token;
     }
     // console.log(headers);
   let response = await fetch(url,{ 
       method: 'POST',    
       headers: headers,    
       body: JSON.stringify(data)  
     });
   if(response.status===200 || response.status===201){
     // console.log(json);
          try{
               let data = await response.json();
               if(data===null ){
                    return null;
               }
               return data;
          }
          catch(ex){
               return null;
          }
        
   }
   else{
        return null;
   }
},

    upload: async (url, file)=>{


          var data = new FormData();
          data.append('uploaded_file', file);
          data.append('preferred_name', new Date().getTime()); 
          data.append('app_id', 'resdf');
          data.append('owner_id', window.userData.userId);
          data.append('upload_reason', 'post');
          data.append('is_public', '1');

          let headers = {
               // 'Content-Type': 'application/json'
          }
          // if( window.token){
          //      headers['Authorization'] = 'Bearer ' +  window.token;
          // }
          // console.log(headers);
        let response = await fetch(url,{ 
            method: 'POST',    
            headers: headers,    
            body:  data
          });
        if(response.status===200 || response.status===201){
          // console.log(json);
               try{
                    let data = await response.json();
                    if(data===null ){
                         return null;
                    }
                    return data;
               }
               catch(ex){
                    return null;
               }
             
        }
        else{
             return null;
        }
     },
     uploadFile: async (file, type) =>{


          var postData = await api.upload(window.VARS.fileApiUrl+'upload', file);
          if(postData){
               if(type=='image'){
                    return {url: window.VARS.fileUrl + postData.name + '_1024.' + postData.extension};
               }
               else{
                    return {url: window.VARS.fileUrl + postData.name + '_mobile.' + postData.extension};
               }
          } 
          return false;  
     },


     sendPost: async (title, contentType, content, description, nsfw, spoiler) =>{
          var postData = await api.post(window.VARS.apiUrl+'posts', {title, contentType, content, description, nsfw, spoiler});
          // console.log('postData', postData);
          if(postData && postData.postId){
               return postData;
          } 
          return false;  
     },



     getUsersSuggestions: async (id) =>{

          var posts = await api.get(window.VARS.apiUrl+'Users/UserSuggestions', {}); 
          if(posts && posts.length && posts.length>0){
               return posts;
          } 
          return false;  
     },

     sendMessage: async (message) =>{

          var posts = await api.post(window.VARS.apiUrl+'Messages/', message); 
          if(posts){
               return posts;
          } 
          return false;  
     },

     getUsersMessages: async (id) =>{

          var posts = await api.get(window.VARS.apiUrl+'Messages/GetUserMessages/'+id, {}); 
          if(posts && posts.length && posts.length>0){
               return posts;
          } 
          return false;  
     },


     getUsersForChat: async () =>{

          var people = await api.get(window.VARS.apiUrl+'Messages/GetPeopleToMessage', {}); 
          if(people && people.length && people.length>0){
               return people;
          } 
          return false;  
     },
     getUsersMessageSessions: async () =>{

          var posts = await api.get(window.VARS.apiUrl+'Messages/', {}); 
          if(posts && posts.length && posts.length>0){
               return posts;
          } 
          return false;  
     },

     getUsersPosts: async (id) =>{

          var posts = await api.get(window.VARS.apiUrl+'Posts/PostsOfUser/'+id, {}); 
          if(posts && posts.length && posts.length>0){
               return posts;
          } 
          return false;  
     },

     getBestPosts: async (maxPostId) =>{
          var posts = await api.get(window.VARS.apiUrl+'Posts/BestPosts'+ (maxPostId?'?maxPostId='+maxPostId:''), {}); 
          if(posts && posts.length && posts.length>0){
               return posts;
          } 
          return false;  
     },


     getRecentPosts: async (maxPostId) =>{
          var posts = await api.get(window.VARS.apiUrl+'Posts/RecentPosts'+ (maxPostId?'?maxPostId='+maxPostId:''), {}); 
          if(posts && posts.length && posts.length>0){
               return posts;
          } 
          return false;  
     },


     getTrendPosts: async (maxPostId) =>{

          var posts = await api.get(window.VARS.apiUrl+'Posts/TrendPosts'+ (maxPostId?'?maxPostId='+maxPostId:''), {}); 
          if(posts && posts.length && posts.length>0){
               return posts;
          } 
          return false;  
     },

     getHomePosts: async (maxPostId) => {

          var posts = await api.get(window.VARS.apiUrl + 'Posts/HomePosts' + (maxPostId ? '?maxPostId=' + maxPostId : ''), {});
          if (posts && posts.length && posts.length > 0) {
               return posts;
          }
          return false;
     },


     getPost: async (id) =>{
          var postData = await api.get(window.VARS.apiUrl+'posts/'+id, {}); 
          if(postData && postData.postId){
               return postData;
          } 
          return false;  
     },

     getChannelPosts: async (maxPostId) =>{
          var posts = await api.get(window.VARS.apiUrl+'Posts/ChannelsPosts'+ (maxPostId?'?maxPostId='+maxPostId:''), {}); 
          if(posts && posts.length && posts.length>0){
               return posts;
          } 
          return false;  
     },

     getUsersComments: async (id) =>{
          var comments = await api.get(window.VARS.apiUrl+'Comments/CommentsOfUser/'+id, {}); 
          if(comments && comments.length){
               return comments;
          } 
          return false;  
     },


 

     getUsersFollowers: async (id) =>{

          var posts = await api.get(window.VARS.apiUrl+'Follows/FollowersOfUser/'+id, {}); 
          if(posts && posts.length && posts.length>0){
               return posts;
          } 
          return false;  
     }, 

     getUsersFollowings: async (id) =>{

          var posts = await api.get(window.VARS.apiUrl+'Follows/FollowsOfUser/'+id, {}); 
          if(posts && posts.length && posts.length>0){
               return posts;
          } 
          return false;  
     },



     getToken: async () =>{

          try{
               var token = await api.getRaw(window.VARS.apiUrl+'Users/GetToken/rjg8j54.53gjh8!_', {}); 
               if(token  ){
               
                    window.token = token;
                    localStorage["token"] = token;
                    
                    return token;
               } 
               return false;  
          }
          catch(ex){
               return false
          }
         
     },

     getUser: async (id) =>{

          var posts = await api.get(window.VARS.apiUrl+'Users/'+id, {}); 
          if(posts  ){
               return posts;
          } 
          return false;  
     },



     saveUser: async (data) =>{
 
          var postData = await api.post(window.VARS.apiUrl+'Users/'+data.id, data);
          if(postData){
               return postData;
          } 
          return false;  

     },



     getComments: async (id) =>{
          var comments = await api.get(window.VARS.apiUrl+'Comments/CommentsOfPost/'+id, {id}); 
          if(comments && comments.length){
               return comments;
          } 
          return false;  
     },



     likePost: async (postId) =>{
          var postData = await api.post(window.VARS.apiUrl+'Likes', {postId:postId});
          if(postData){
               return postData;
          } 
          return false;  
     },

     unlikePost: async (postId) =>{
          var postData = await api.post(window.VARS.apiUrl+'Likes', {postId:postId});
          if(postData){
               return postData;
          } 
          return false;  
     },


     likeCommment: async (commentId) =>{
          var postData = await api.post(window.VARS.apiUrl+'Likes', {commentId:commentId});
          if(postData){
               return postData;
          } 
          return false;  
     },


     follow: async (userId) =>{
          var postData = await api.post(window.VARS.apiUrl+'Follows/'+userId, {id:userId});
          if(postData){
               return postData;
          } 
          return false;  
     },


     unfollow: async (userId) =>{
          var postData = await api.post(window.VARS.apiUrl+'Follows/'+userId, {id:userId});
          if(postData){
               return postData;
          } 
          return false;  
     },

     sendComment: async (description, contentType, content,  postId ) =>{
          var postData = await api.post(window.VARS.apiUrl+'Comments', {description, contentType, content, postId});
          if(postData && postData.postId){
               return postData;
          } 
          return false;  
     },

     forgetPassword: async (name, email)=>{ 
         
          if(email && email.trim().length>10 && email.indexOf('@')>-1 && name && name.length>1 ){
             var success = await api.post(window.VARS.apiUrl+'/forget', {name, email});
             if(success){
                 return true;
             }
          }
          return false;
        },

     checkLogin: async ()=>{  

          var token =  localStorage["token"]; 

          if(token){
               // console.log('check login workin')
               try{
                    var userData = await api.getRaw(window.VARS.apiUrl+'Users/VerifyToken', {});
                    console.log('userData', userData);
                    if(userData){ 
                       return true;
                    }
               }
               catch(ex){
                    return false;
               }
           
          }
          return false;
     
     },

     login: async (email, password)=>{ 
         
          if(email && email.trim().length>10 && email.indexOf('@')>-1 && password && password.length>5 ){
             var userData = await api.post(window.VARS.apiUrl+'Users/Login', {email, password});
             console.log(userData);
             if(userData && userData.userId){
               console.log(userData);
               window.userData = userData;
               localStorage["user"] = JSON.stringify(userData);
               localStorage["token"] = userData.token;
               window.token = userData.token;
                return userData;
             }
          }
          return false;
        },

     register: async (userName, email, avatar, password, country, language)=>{ 
         
          if(  email && email.trim().length>10 && email.indexOf('@')>-1 && 
               password && password.trim().length>5 && 
               userName && userName.trim().length>1 && 
               avatar && avatar.trim().length>1 && 
               country && country.trim().length>0
          ){
               var userData = await api.post(window.VARS.apiUrl+'Users/Register', {userName, email, avatar, password, country, language});
               console.log(userData);
               if(userData && userData.userId){
                    localStorage["user"] = JSON.stringify(userData);
                    localStorage["token"] = userData.token;
                    // window.sns.user = userData;
                    // localStorage["userhash"] = userData.hash;
                    // localStorage["userdata"] = userData;
                    return userData;
               }
               return userData;
          }
          return false;
     },

 

}



