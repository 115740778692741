 
 import React, { Component, useEffect, useRef, useState }  from 'react';
 
 import './SuggestedProfiles.scss';   
import UserItem from '../Shared/UserItem';
import { api } from '../shorten/Shorten.Api';
import Loading from '../Shared/Loading';
 
function SuggestedProfiles({userId}) {
    
  
    const [users, setUsers] = useState([]);
    const [loaded, setLoaded] = useState(false);


    useEffect(() => {
      loadPosts(); 
    }, [userId]); 

    const loadPosts =  async () => {
      if(!(window.userData || {}).userId){
        setLoaded(true);
        return;
      }
      let result   = await api.getUsersSuggestions(userId);
      
      
  
      result = result.map(r=>{
        r.insertedDate = new Date(r.insertedDate) || new Date();
        
        return r;
      });
        setLoaded(true);
        setUsers(result);
    }
 

    

  return (
      <div className='suggested-profiles'> 
          <div className='user-list'>
            <div className='user-list-title'>Suggestions for you</div>
            {!loaded && <Loading />} 
            {users.map(u=> <UserItem key={u.userName} user={u} onFollow={()=>{}} onUnFollow={()=>{}} /> )} 

          </div>
      </div>      
  
  );
}

export default SuggestedProfiles;
