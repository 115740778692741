 
 import React, { Component, useEffect, useRef, useState }  from 'react';
 import './Main.scss';  
import PostListingMainPage from './PostListingMainPage';
 
function Best() { 
    return <PostListingMainPage type={'best'} />
}

export default Best;
